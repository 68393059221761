import { trailerActionTypes } from "./trailer.types"

const initialState = {
    trailerIsClosed: true,
    trailerContent: {}
}

const trailerReducer = (state = initialState, action) => {
    switch (action.type) {
        case trailerActionTypes.SHOW_MODAL_TRAILER:
            return {
                ...state,
                trailerIsClosed: false,
                trailerContent: { ...action.payload }
            }
        case trailerActionTypes.HIDE_MODAL_TRAILER:
            return {
                ...state,
                trailerIsClosed: true,
                trailerContent: {}
            }
        default:
            return state
    }
}

export default trailerReducer
