export const getOneMonthAgoReleaseDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    let formattedDate = date.toJSON().slice(0,10);

    return formattedDate;
}

export const dateToYearOnly = date => date.slice(0,4);

export const formatDate = date => {
    if(date){
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return dd + '/' + mm + '/' + yyyy;
    }else{
        return "01/01/2013";
    }
    
}

export const maturityLevel = data => {
    const maturity_level = 
            data?.maturity_level === 'ADULT' 
        ? "A"
        :    data?.maturity_level === 'SEVEN_PLUS' 
        ? "U/A 7+" 
        :   data?.maturity_level === "KIDS" 
        ?   "Kids"
        : data?.maturity_level === 'SIXTEEN_PLUS' 
        ?    "U/A 16+" :  "U/A 13+"
    return maturity_level;
}

export const formatRunTime = data => {
    if(data){
        const hours = Math.floor(data / 3600);
        const minutes = Math.floor((data % 3600) / 60);
        return `${hours}h  ${minutes}m`;
    }else{
        return "00h  00m";
    }
  };

export const capitalizeFirstLetter = text => (
    text.charAt(0).toUpperCase() + text.slice(1)
);

export const fallbackTitle = data => (
    data?.title || data?.original_name
);

export const randomize = data => (
    [...data].sort(() => 0.5 - Math.random())
);


export const truncate = (text, n) => (
    text?.length > n ? text.substr(0, n - 1) + "..." : text
);

export const filterImg = (imageArrays, quality, layout) => (
    imageArrays?.filter((val) => val?.quality == quality && val.image_type === layout)[0]
);

export const filterVideo = (videoArrays, quality) => (
    videoArrays?.filter((val) => val?.quality == quality)[0]
);


export const videRumTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h  ${minutes}m`;
};

export const setInLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  export const clearInLocalStorage = (key) => {
    localStorage.removeItem(key);
  };
  
  export const getFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };