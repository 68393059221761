import "./creditPoster.scss";
import { CREDIT_BASE_URL, CAST_BGIMG, CREW_BGIMG } from "../../requests";

const isImgLink = (url)  =>{
	return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

const CreditPoster = result => {
	
	const { item } = result;
	const fallbackNoImg = item?.job || item?.department ? CREW_BGIMG : CAST_BGIMG;
	const profileImage = isImgLink(CREDIT_BASE_URL + item.profileImg)? CREDIT_BASE_URL + item.profileImg : fallbackNoImg;

	return (
		<>
		<div className={`CreditPoster__poster`}>
			{!profileImage ? (
				<img src={fallbackNoImg} alt={item.name} />
			) : (
				<>
					<img src={profileImage} alt={item?.name} />
					<div className="CreditPoster__poster__fallback">
						<span>{item.name}</span>
					</div>
				</>
			)}
		</div>
		<div className="CreditPoster__poster-info">
			<div className="CreditPoster__poster-info--title">
				<h3>{item.name}</h3>
				{item?.character ? (
					<h4>{item.character}</h4>
				) : (
					<>
					<h4>{item.job}</h4>
					<h4>{item.department}</h4>
					</>
				)}
			</div>
		</div>
		</>
	);
};

export default CreditPoster;