import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { fetchHomeMovieDataConfig, fetchCabelTvMovieDataConfig, fetchLatestMovieDataConfig  } from "../dataConfig";

export const useRetrieveData = (type) => {

    const dispatch = useDispatch()
    const [data, setData] = useState(null)

    useEffect(() => {
        let selectedConfigArray = null;
        switch (type) {
            case "movies":
                selectedConfigArray = fetchHomeMovieDataConfig;
                break;
            case "cabeltv":
                selectedConfigArray = fetchCabelTvMovieDataConfig;
                break;
            case "latest":
                selectedConfigArray = fetchLatestMovieDataConfig;
                break;
            case "popular":
                selectedConfigArray = fetchHomeMovieDataConfig;
                break;
            default:
                break;
        }

        let isPage = true;
        const rowsData = selectedConfigArray.map(el => {
            dispatch(el.thunk(el.url, isPage))
            return {
                id: el.id,
                title: el.title,
                description: el.description,
                selector: el.selector,
            }
        })
        setData(rowsData)

    }, [type, dispatch])

    return data
}
