import "./rowPoster.scss";
import { useNavigate } from "react-router-dom";
import { BASE_IMG_URL, FALLBACK_IMG_LANDSCAPE, FALLBACK_IMG_PORTRAIT } from "../../requests";
import { useDispatch } from "react-redux";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import { FaPlus, FaMinus, FaPlay, FaChevronDown } from "react-icons/fa";
import { filterImg } from "../../utils";
import { showModalDetail } from "../../redux/modal/modal.actions";

const RowPoster = result => {
	
	const { item, item: { title, title_id, genres, }, layout, isFavourite } = result;
	const imageArrays = item?.VideoContents?.items[0]?.Images?.items;
	const filteredImage_row = filterImg(imageArrays, "P360", layout);
	const posterImage_row = BASE_IMG_URL + filteredImage_row?.image_path +"/"+ filteredImage_row?.quality.toLowerCase() + filteredImage_row?.file_name;
	const filteredImage = filterImg(imageArrays, "P360", "LANDSCAPE");
	const posterImage = BASE_IMG_URL + filteredImage?.image_path +"/"+ filteredImage?.quality.toLowerCase() + filteredImage?.file_name;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const FALL_BACK_IMAGE = layout ==='LANDSCAPE'? FALLBACK_IMG_LANDSCAPE : FALLBACK_IMG_PORTRAIT;

	const handleAdd = event => {
		event.stopPropagation();
		dispatch(addToFavourites({ ...item, isFavourite }));
	};
	const handleRemove = event => {
		event.stopPropagation();
		dispatch(removeFromFavourites({ ...item, isFavourite }));
	};
	const handleModalOpening = () => {
		dispatch(showModalDetail({ ...item, fallbackTitle: title, posterImage, isFavourite }));
	}
	const handlePlayVideo = (e, value) => {
		e.stopPropagation();
		navigate('/play/'+value);
	};
	

	return (
		<div className={`Row__poster`} onClick={handleModalOpening}>
			{posterImage_row ? (
				<img src={posterImage_row} alt={title} />
			) : (
				<>
					<img src={FALL_BACK_IMAGE} alt={title} />
					<div className="Row__poster__fallback">
						<span>{title}</span>
					</div>
				</>
			)}
			<div className="Row__poster-info">
				<div className="Row__poster-info--iconswrp">
					<button
						className="Row__poster-info--icon icon--play"
						onClick={(e) => handlePlayVideo(e, title_id)}
					>
						<FaPlay />
					</button>
					{!isFavourite
						? (
							<button className='Row__poster-info--icon icon--favourite' onClick={handleAdd}>
								<FaPlus />
							</button>
						): (
							<button className='Row__poster-info--icon icon--favourite' onClick={handleRemove}>
								<FaMinus />
							</button>
						)}
					<button className='Row__poster-info--icon icon--toggleModal'>
						<FaChevronDown onClick={handleModalOpening}/>
					</button>
				</div>
				<div className="Row__poster-info--title">
					<h3>{title}</h3>
				</div>
				<div className="Row__poster-info--genres">
					{genres?.items && genres?.items.map(item => (
						<span key={`Genre--id_${item.category.category_id}`} className="genre-title">{item.category.name}</span>
					))}
				</div>
			</div>
		</div>
	);
};

export default RowPoster;