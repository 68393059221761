import "./row.scss";
import RowPoster from "../RowPoster/RowPoster";
import SkeletonElement from "../SkeletonElement/SkeletonElement";
import SkeletonPoster from "../SkeletonPoster/SkeletonPoster";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import useViewport from "../../hooks/useViewport";
import { FiChevronRight } from "react-icons/fi";

// Swiper
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
SwiperCore.use([Navigation, Pagination]);

const Row = ({ selector }) => {
	const { width } = useViewport();
	const rowData = useSelector(selector);
	const { loading, error, data } = rowData;
	const { pathname } = useLocation();
	const widgets = data.filter((item) => (item?.type != 'SLIDER'));
	let path = pathname.replace(/\\|\//g,'');
	const slider = path ==="mylist" || path ==="popular" || path ==="movies" ? "Row__top" : "";

	const customSwiperParams = {
        observer: true,
        observeParents: true,
		navigation: width < 625 ? false : true,
		pagination: true,
		loop: false,
		grabCursor: false,
		draggable: false,
		preventClicksPropagation: true,
		preventClicks: true,
		slideToClickedSlide: false,
		allowTouchMove: true,
		//loopAdditionalSlides: width >= 1378 ? 5 : width >= 998 ? 3 : width >= 625 ? 2 : 2,
    };

	const breakpoints = (limit) => {
		if(limit >= 4){
			const set={
				1378: { slidesPerView: limit, slidesPerGroup: limit },
				998: { slidesPerView: 4, slidesPerGroup: 4 },
				625: { slidesPerView: 3, slidesPerGroup: 3 },
				330: { slidesPerView: 2, slidesPerGroup: 2 },
				0: { slidesPerView: 1.5, slidesPerGroup: 1 }
			}
			return set;
		}else if(limit <= 3){
			const set={
				1378: { slidesPerView: limit, slidesPerGroup: limit },
				998: { slidesPerView: limit, slidesPerGroup: limit },
				625: { slidesPerView: limit, slidesPerGroup: limit },
				330: { slidesPerView: 1.5, slidesPerGroup: 1 },
				0: { slidesPerView: 1, slidesPerGroup: 1 }
			}
			return set;
		}
		
		
	}

	const rightMouseOver = (e) => {
		if (e.currentTarget.classList.contains('right')) {e.currentTarget.parentElement.classList.add('is-right')}
		else if (e.currentTarget.classList.contains('left')) {e.currentTarget.parentElement.classList.add('is-left')}
	}

	const rightMouseOut = (e) => {
		e.currentTarget.parentElement.classList.remove('is-right', 'is-left')
	}

	const insertPositionClassName = (index) => {
		const i = index + 1

		if (i === 1) return 'left'
		else if (i === 20) return 'right'

		if (width >= 1378) {
			if ([7, 13, 19].includes(i)) return 'left'
			else if ([6, 12, 18].includes(i)) return 'right'
		} else if (width >= 998) {
			if ([5, 9, 13, 17].includes(i)) return 'left'
			else if ([4, 8, 12, 16].includes(i)) return 'right'
		} else if (width >= 768) {
			if ([4, 7, 10, 13, 16].includes(i)) return 'left'
			else if ([3, 6, 9, 12, 15, 18].includes(i)) return 'right'
		}
	}
	

	return (
		<>
		{widgets && widgets.map((data, i) => (
		<div key={i} className={`Row ${slider}`} >
			{error && <div className='Row__not-loaded'>Oops, an error occurred.</div>}
			{loading ?
				(
					<div className='Row__not-loaded'>
						<SkeletonElement type="title" />
						<SkeletonPoster />
					</div>
				) : (			
				<>
					<h3 className="Row__title">
						<Link to={"#"+pathname+"/"+data.go_to_link}>
							<span>{data.title}</span>
							<span className='Row__showmore'>Show all <FiChevronRight/></span>
						</Link>
					</h3>
					<div className="Row__poster--wrp">						
						<Swiper
							key={data.id}
							{...customSwiperParams}
							//loopAdditionalSlides= {width >= 1378 ? 5 : width >= 998 ? 3 : width >= 625 ? 2 : 1}
							breakpoints={breakpoints(data.widget_limit)}
								
							
						>
							{!loading && data.VideoTitles.items &&
								data.VideoTitles.items.map((result, i) => (
									<SwiperSlide
										key={i}
										className={insertPositionClassName(i)}
										onMouseOver={rightMouseOver}
										onMouseOut={rightMouseOut}
									>
										<RowPoster
											item={result.videoTitle}
											layout={data.layout}
											isLarge={false}
											isFavourite={false}
											key={i + 5}
										/>
									</SwiperSlide>
								))
							}
						</Swiper>
					</div>
				</>
			)}
		</div>
		))}
		</>
	);
};

export default Row;