import { createSelector } from "reselect";

const selectGet = (state) => state.get;


export const selectGetResults = createSelector(
	[selectGet],
	get => get.getResults
);

export const selectGetInputValue = createSelector(
	[selectGet],
	get => get.inputValue
);
