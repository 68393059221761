// //import { applyMiddleware } from "redux"
// import { configureStore } from '@reduxjs/toolkit'
// import thunk from 'redux-thunk'
// //import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from "redux-logger";
// import createSagaMiddleware from 'redux-saga';
// import { persistStore } from "redux-persist";
// import rootReducer from "./rootReducer";
// import { rootSaga } from "./rootSaga";



// // const enhancers = composeWithDevTools(
// //     applyMiddleware(...middlewares, sagaMiddleware)
// // );

// if (process.env.NODE_ENV === 'development') {
//     middlewares.push(logger);
// }
// const middlewares = [thunk];
// const sagaMiddleware = createSagaMiddleware();


// export const store = configureStore({
//     reducer:rootReducer, middlewares,
//      devTools: process.env.NODE_ENV === 'development',
    
//  });
// sagaMiddleware.run(rootSaga);
// export const persistor = persistStore(store);

// export default { store, persistor };


import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga';
import { persistStore } from "redux-persist";

import reducer from './rootReducer';
import { rootSaga } from './rootSaga';

const devMode = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();

const middleware = [thunk, sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
}

export const store = configureStore({
    reducer, middleware,
    devTools: devMode
    
});
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default { store, persistor };


