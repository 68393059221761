import { useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box, styled, Fade, Stack, Typography, Button } from '@mui/material';
import { PlayCircleOutline, CloseOutlined, Facebook, Twitter, YouTube, Instagram  } from '@mui/icons-material';
import { formatDate, maturityLevel, formatRunTime } from "./../../utils";
import Navbar from "../../components/Navbar/Navbar";
import Credits from "./../../components/Credits/Credits";
import Footer from "../../components/Footer/Footer";
//import { isMobile } from 'react-device-detect';
//import UAParser from "ua-parser-js";
import VideoPlayer from 'react-player';
import PlayerControls from './PlayerControls';
import PlayerOverlay from './PlayerOverlay';
import { INITIAL_STATE, reducer } from './Player.reducer';
import {useIdleTimer} from 'react-idle-timer';
import Loader from "../Loader/Loader";
import "./player.scss"




const StyledPlayer = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  position: relative;
  width: 100%;
  height: ${({ state }) => (state.playing ? '100vh' : '100%')};
  user-select: none;
  background: #000;
  

  &:hover {
    .Player__overlay-title {
        opacity: ${({ state }) => (state.showctrl ? '0.5' : '0')};
    }
  }

  .Player__controls,
  .Player__overlay-close {
      opacity: ${({ state }) => (state.showctrl ? '0.7' : '0')};
  }

  .react-player__preview:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
  }


  .Player__overlay-close {
    position: absolute;
    top: 2.5em;
    right: 1em;
    opacity: ${({ state }) => (state.showctrl ? '0.7' : '0')};
    transition: opacity 0.2s ease-in-out;
    text-shadow: 2px 2px 7px black;
    margin: 0;
    padding: 0;
    z-index: 9;

    &:hover {
      opacity: 1;
      transform:scale(1.3);
      transition: 0.5s ease-in;
    }
  }

  .Player__overlay-title {
    position: absolute;
    top: 5vh;
    width: 100%;
    opacity: ${({ state }) => (state.showctrl ? '0.7' : '0')};
    transition: opacity 0.2s ease-in-out;
    text-shadow: 2px 2px 7px black;
    height: 50px;
    text-align: center;
    font-weight: 600;

    &:hover {
      opacity: 1;
    }
  }


  .Player__overlay-loading {
    position:absolute;
    user-select: none;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .Player__overlay-loading .Loader {
    width: 80px;
    height: 80px;
  }
  .Player__overlay-loading .Loader:after {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: black;
  }
  .Player__info{
      &_bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000000;
        background: linear-gradient(103deg, rgba(0,0,0,0.9) 40%, rgba(255,255,255,0) 100%);
      }

      &--detail {
        width: 80%;
        bottom: 0vh;
        left: 4vw;
        display: block;
        position: inherit;

        ${props => props.theme.breakpoints.up("sm")} {
          bottom: 5vh;
          width: 70%;
          left: 5vw;
        }
        ${props => props.theme.breakpoints.up("md")} {
          bottom: 5rem;
          width: 75%;
          left: 5vw;
        }
        ${props => props.theme.breakpoints.up("lg")} {
          bottom: 7rem;
          width: 80%;
          left: 5vw;
        }
    }

      &--title {
        font-size: 4vw;
        ${props => props.theme.breakpoints.up("xs")} {
          font-size: 5vw;
        }
        ${props => props.theme.breakpoints.up("sm")} {
          font-size: 6vw;
        }
        ${props => props.theme.breakpoints.up("md")} {
          font-size: 2.7rem;
        }
        ${props => props.theme.breakpoints.up("lg")} {
          font-size: 3rem;
        }
      }

      &--description {
        font-size: 1vw;
        display: none;
        ${props => props.theme.breakpoints.up("sm")} {
          font-size: 2vw;
          display: block;
        }
        ${props => props.theme.breakpoints.up("md")} {
          font-size: 1rem;
          display: block;
        }
        ${props => props.theme.breakpoints.up("lg")} {
          font-size: 1.2rem;
          display: block;
        }
      }

      &--date-time {
        font-size: 2vw;

        ${props => props.theme.breakpoints.up("sm")} {
          font-size: 2vh;
        }
        ${props => props.theme.breakpoints.up("md")} {
          font-size: 1.1rem;
        }
        ${props => props.theme.breakpoints.up("lg")} {
          font-size: 1.3rem;
        }
      }

      &--playButton {
        left: -1.5vh;
        font-size: 3vw;
        color: white;

        ${props => props.theme.breakpoints.up("sm")} {
          font-size: 2.5vh;
        }
        ${props => props.theme.breakpoints.up("md")} {
          font-size: 1.25rem;
        }
        ${props => props.theme.breakpoints.up("lg")} {
          font-size: 1.5rem;
        }
        &--icon {
          font-size: 8vw;
          color: white;
          ${props => props.theme.breakpoints.up("sm")} {
            font-size: 7vh;
          }
          ${props => props.theme.breakpoints.up("md")} {
            font-size: 4rem;
          }
          ${props => props.theme.breakpoints.up("lg")} {
            font-size: 4.5rem;
          }
        }
      }
  }





}`;

const Player = (props) => {
  //const parser = new UAParser(window?.navigator?.userAgent);
  const { url, data, poster, cast, crew } = props;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
 // const [lang, setLang]=useState();
  const playerRef = useRef(null);
  const wrapperRef = useRef(null);


  // console.log(parser?.getDevice());
  // console.log(parser?.getOS());
  // console.log(parser?.getCPU());
  // console.log(parser?.getBrowser());

  const handlePreview = () => {
    dispatch({ type: 'PLAY' });
    dispatch({ type: 'PREVIEW', payload: false });    
    dispatch({ type: 'CONTROLS', payload: true });
    let progress = playerRef?.current?.getCurrentTime()? playerRef?.current?.getCurrentTime() : 0;
    dispatch({ type: 'PLAY_FROM', payload: progress });
 }

  const handelCloseButton = () => {
    dispatch({ type: 'PAUSE' });
    dispatch({ type: 'PREVIEW', payload: true });
    dispatch({ type: 'CONTROLS', payload: false });
  };

  // const handleToggle = () => {
  //   dispatch({ type: 'TOGGLE_PLAY' });
  // };

  const handlePause = () => {
    dispatch({ type: 'PAUSE' });
  };

  const handlePlay = () => {
    dispatch({ type: 'PLAY' });
    dispatch({ type: 'CONTROLS', payload: true });
  };

  const handleEnded = () => {
    dispatch({ type: 'PAUSE' });
    dispatch({ type: 'CONTROLS', payload: false });
  };

  const handleProgress = (progress) => {
    dispatch({ type: 'SEEK', payload: progress.playedSeconds });
  };

  const handleDuration = (duration) => {
    dispatch({ type: 'DURATION', payload: duration });
  };

  const handleSingleClick = () => {
    dispatch({ type: 'TOGGLE_PLAY' });
  };


  const handleonIdle = () => {
    dispatch({ type: 'CONTROLS', payload: false });
  }

  const handleonActive = () => {
    dispatch({ type: 'CONTROLS', payload: true });
  }

const controlHandler = useIdleTimer({onIdle:handleonIdle, onActive:handleonActive, timeout:5000});

useEffect(() => {
  controlHandler
},[controlHandler]);
 

  const typeHD = "HD";


  useEffect(() => {
    const currentPlayer = playerRef?.current?.getInternalPlayer();
    const textTracks = currentPlayer?.textTracks;
    if(state.subtitle && state.language && data?.title_id === '20231100'){
      const track = document.createElement("track");
      const listTrack = document?.querySelector("track:first-child");
        track.kind = "subtitles";
        track.srclang = state.language;
        track.src = `https://cdn.chmovies.com/20231100/subtitles/manichitrathazhu.${state.language}.vtt`;
        listTrack && currentPlayer?.removeChild(listTrack);   
        currentPlayer?.appendChild(track);  
    }
    for (var i = 0; textTracks?.length && i < textTracks.length; i++) {
      if (textTracks[i].language === state.language) {
        textTracks[i].mode = "showing";
      } else {
        textTracks[i].mode = "hidden";
      }
    }
  }, [state.subtitle, state.language, data?.title_id]);

 
  return (
    <>
      <StyledPlayer state={state} ref={wrapperRef}  >
          {state.preview && (
          <Navbar />
          )}
          
          <VideoPlayer
            id="Player"
            className="Player"
            ref={playerRef}
            url={url}
            width="100%"
            height="auto"
            light={false}
            controls={state.controls}
            loop={state.loop}
            muted={state.muted}
            playing={state.playing}
            playbackRate={state.playbackRate}
            volume={state.volume}
            subtitle={state.subtitle}
            onPlay={handlePlay}
            onEnded={handleEnded}
            onPause={handlePause}
            onDuration={handleDuration}
            onProgress={handleProgress}
            onPlaybackRateChange={""}
            onClick={handleSingleClick}
            config={{
              file: {
                attributes: {crossOrigin: "true"},
                tracks: []
              }
            }}
          />
          
          

        {state.preview && ( 
          <Stack direction="row" alignItems="left" className={'Player__info'}>
              <>
                <Box className={'Player__info--detail'} sx={{zIndex: '7'}}>
                      <Fade in>
                          <Typography className='Player__info--title' variant="h4" sx={{ mt:1, fontWeight:'600', color:'#d39e00' }}>
                            {data?.title}                        
                          </Typography>
                      </Fade>
                      <Fade in className='Player__info--description'>
                          <Typography sx={{ mb:1, color:'white'}}>
                            {data?.description}
                          </Typography>
                      </Fade>
                      <Fade in>
                          <Typography className='Player__info--date-time' variant="span" sx={{ mb:2, color:'white' }}>
                            <>{data?.release_date?.slice(0, 4)} <span className='maturity_box'>{maturityLevel(data?.maturity_level)}</span> {formatRunTime(data?.runtime)} <span className='hd_box'>{typeHD}</span></>
                          </Typography>
                      </Fade>
                      <Box>
                      <Button className="Player__info--playButton" onClick={handlePreview}>
                          <PlayCircleOutline className="Player__info--playButton--icon" sx={{ color: 'white'}} />
                          <Typography variant='span' sx={{ color: 'white'}} >PLAY NOW</Typography>
                      </Button>
                      </Box>
                      
                  </Box>
                  <Box sx={{position:'absolute', backgroundImage: `url(${poster})`, width:'100%', height:'100%',backgroundRepeat: "no-repeat",
      backgroundSize: "cover", backgroundPosition: 'center center', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '0'}}></Box>
                  <Box className="Player__info_bg"></Box>
                  <Box className="Player__Bottom_shadow"></Box>
                  </>
              </Stack>
          )} 
          {!state.preview && !playerRef?.current?.player?.isReady && (
              <Box className={'Player__overlay-loading'}>
                <Loader />
              </Box> 
          )} 
          {!state.preview && !state.playing && (
              <PlayerOverlay state={state} data={data} />
          )} 
          {!state.preview && state.showctrl && (
          <>
          <Box className={'Player__overlay-title'} sx={{ fontSize: '2.2vw'}}>
            {data?.original_title ? data?.original_title : data?.title}
          </Box>
          
          

          <Box className={'Player__overlay-close'}>
            <Fade in color={'white'} onClick={handelCloseButton}>
              <CloseOutlined sx={{ fontSize: '3vw', color: 'white', cursor:'pointer' }} />
            </Fade>
          </Box>
          <PlayerControls state={state} dispatch={dispatch} playerRef={playerRef} wrapperRef={wrapperRef} />
          </>  
          )} 
      </StyledPlayer>
      {state.preview && !state.playing && (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={9}>
          <Credits data={cast} title="Cast Members" />
          <Credits data={crew} title="Crew Members" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box className="Player__poster-info">
            <Box className="Player__info--social">
              <Box variant="span" className='Player__info--social-label' sx={{ marginBottom: '5px' }}>Follow Us at: </Box>
              <Box>
                <Link to={"https://www.facebook.com/chmoviesott"} target="_blank">
                  <Facebook  sx={{ color: 'white', fontSize: '2rem' }} />
                </Link>
                <Link to={"https://www.twitter.com/chmoviesott"} target="_blank">
                  <Twitter  sx={{ color: 'white', fontSize: '2rem', marginRight: '5px' }} />
                </Link>
                <Link to={"https://www.instagram.com/chmoviesott"} target="_blank">
                  <Instagram  sx={{ color: 'white', fontSize: '2rem', marginRight: '5px' }} />
                </Link>
                <Link to={"https://www.youtube.com/@chmoviesott"} target="_blank">
                  <YouTube  sx={{ color: 'white', fontSize: '2rem',  marginRight: '5px' }} />
                </Link>
              </Box>
						</Box>
          </Box>
          <Box className="Player__poster-info">
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Release Date: </span>
							<span className="Player__info--extras-description">{formatDate(data?.release_date)}</span>
						</Box>
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Original Title: </span>
							<span className="Player__info--extras-description">{data?.original_title}</span>
						</Box>
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Original Language: </span>
							<span className="Player__info--extras-description">{data?.original_language}</span>
						</Box>
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Rating: </span>
							<span className="Player__info--extras-description">{data?.vote_average}</span>
						</Box>
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Subtitles: </span>
							<span className="Player__info--extras-description">English, മലയാളം, தமிழ், हिंदी</span>
						</Box>
            {data?.audio_languages && (
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Audio: </span>
							<span className="Player__info--extras-description">{data?.audio_languages}</span>
						</Box>
            )}
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Maturity Rating: </span>
							<span className="Player__info--extras-description">{maturityLevel(data?.maturity_level)}</span>
						</Box>
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Run TIme: </span>
							<span className="Player__info--extras-description">{formatRunTime(data?.runtime)}</span>
						</Box>
            <Box className="Player__info--extras">
							<span className='Player__info--extras-label'>Available in : </span>
							<span className="Player__info--extras-description">SD | HD | UHD</span>
						</Box>
          </Box>
        </Grid>
      </Grid>
      )}
      
    {state.preview && (
    <Footer />
    )}
    </>
    
  );
};

export default Player;
