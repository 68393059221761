import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Homepage from "./pages/Homepage/Homepage"
import Movies from "./pages/Movies/Movies"
import CabelTV from './pages/CabelTV/CabelTV';
import Popular from "./pages/Popular/Popular";
import MyList from './pages/MyList/MyList';
import Auth from "./pages/Auth/Auth";
import Search from "./pages/Search/Search";
import Category from "./pages/Category/Category";
import DetailModal from "./components/DetailModal/DetailModal";
import TrailerModal from "./components/TrailerModal/TrailerModal";
import SplashAnimation from "./components/SplashAnimation/SplashAnimation";
import PlayVideo from "./pages/PlayVideo/PlayVideo";
import Detail from "./pages/Trailer/Trailer";
import Aboutus from "./pages/Page/Aboutus";
import Privacy from "./pages/Page/Privacy";
import Terms from "./pages/Page/Terms";
import { selectCurrentUser } from './redux/auth/auth.selectors';
import { selectSearchResults } from "./redux/search/search.selectors";
import { checkUserSession } from "./redux/auth/auth.actions";




const App = () => {

    const currentUser = useSelector(selectCurrentUser);
    const searchResults = useSelector(selectSearchResults);
    
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(checkUserSession());
    }, [dispatch])
   
    return (
        <div className="App">
            {currentUser && (
                <>
                    <DetailModal />
                    <TrailerModal />
                </>
            )}
            <AnimatePresence>
                <>
                    <Routes location={location} key={location.pathname}>
                        <Route 
                            path="/" element={<Homepage />}
                        ></Route>
                        <Route 
                            path="/login" element={!currentUser ? <Auth /> : <Navigate to='/home'/>}
                        ></Route>
                        <Route 
                            path="/splash" element={<SplashAnimation />}
                        ></Route>
                        <Route 
                            path="detail/:id" element={<Detail />}
                        ></Route>
                        <Route 
                            path="/play/:id" element={currentUser ? <PlayVideo />  : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="/mylist" element={currentUser ? <MyList /> : <Navigate to='/login'/>}
                        ></Route> 
                        <Route
                            path="/search" element={currentUser ?  searchResults && <Search results={searchResults} /> : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="/home" element={<Homepage />}
                        ></Route>
                        <Route
                            path="/home/:categoryName" element={currentUser ? <Category /> : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="/cabeltv" element={<CabelTV />}
                        ></Route>
                        <Route
                            path="cabeltv/:categoryName" element={currentUser ? <Category /> : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="movies" element={currentUser ? <Movies /> : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="movies/:categoryName" element={currentUser ? <Category /> : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="popular" element={currentUser ? <Popular /> : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="popular/:categoryName" element={currentUser ? <Category /> : <Navigate to='/login'/>}
                        ></Route>
                        <Route
                            path="/about-us" element={<Aboutus />}
                        ></Route> 
                        <Route
                            path="/privacy-policy" element={<Privacy />}
                        ></Route>
                        <Route
                            path="/terms-service" element={<Terms />}
                        ></Route>              
                    </Routes>
                </>
            </AnimatePresence>
        </div>
    )
}

export default App;
