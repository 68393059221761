import "./homepage.scss";
import Banner from "../../components/Banner/Banner";
import Row from "../../components/Row/Row";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";

const Homepage = () => {
    const movieData = useRetrieveData('movies');
    return (
        <motion.div
            className="Homepage"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Navbar />
            {movieData && movieData.map(props => (
                <div key={props.id}>
                    <Banner key={props.id} {...props} />
                    <Row key={props.id + 1} {...props} />
                </div>
            ))}
            <Footer />
        </motion.div>
    )
}

export default Homepage
