import axios from "../../axiosInstance";
import { moviesActionTypes } from "./movies.types";

// const config = {
// 	mode: 'no-cors',
//     headers: {'Access-Control-Allow-Origin': '*'}
// }


// const url = 'https://qp6q5kvkvze5dcmpuwzh4enddm.appsync-api.ap-south-1.amazonaws.com/graphql';





// HomeMovies
export const fetchHomeMoviesRequest = () => ({
	type: moviesActionTypes.FETCH_HOME_MOVIES_REQUEST,
});

export const fetchHomeMoviesSuccess = (homeMovies, isPage) => ({
    type: isPage
        ? moviesActionTypes.FETCH_HOME_MOVIES_SUCCESS
        : moviesActionTypes.LOAD_MORE_HOME_MOVIES_SUCCESS,
	payload: homeMovies,
});

export const fetchHomeMoviesFailure = error => ({
	type: moviesActionTypes.FETCH_HOME_MOVIES_FAILURE,
	payload: error,
});

// export const fetchHomeMoviesAsync = (fetchUrl, isPage) => {
	
// 	return dispatch => {
// 		dispatch(fetchHomeMoviesRequest());
// 		axios
// 			.get(fetchUrl)
// 			.then(res => {
// 				console.log("mydata", res)
//				const homeMovies = resData.map(el => ({
// 					...el,
// 					isFavourite: false,
// 				}));
//                 if (isPage) {
//                     dispatch(fetchHomeMoviesSuccess(homeMovies, isPage));
//                 } else dispatch(fetchHomeMoviesSuccess(homeMovies));
// 			})
// 			.catch(error => {
// 				console.log("error", error)
// 				const errorMessage = error.message;
// 				dispatch(fetchHomeMoviesFailure(errorMessage));
// 			});
// 	};
// };


export const fetchHomeMoviesAsync = (fetchUrl, isPage) => {
	let data = JSON.stringify({query: fetchUrl});
	let headers = {'x-api-key': 'da2-y7u3lgdrevdkrcxikpip5klpru'};
	return dispatch => {
		dispatch(fetchHomeMoviesRequest());
		axios
		.post('/', data, {headers: headers})
		.then((res) => {const homeMovies = res.data.data.widgetbyPage.items.map(el => ({
				...el,
				isFavourite: false,
		}));
		if (isPage) {
			dispatch(fetchHomeMoviesSuccess(homeMovies, isPage));
		} else dispatch(fetchHomeMoviesSuccess(homeMovies));
	})
	.catch((error) => {
		const errorMessage = error.message;
		dispatch(fetchHomeMoviesFailure(errorMessage))
	});
	}
};




// Cabel TV Movies
export const fetchCabelTvMoviesRequest = () => ({
	type: moviesActionTypes.FETCH_CABELTV_MOVIES_REQUEST,
});

export const fetchCabelTvMoviesSuccess = (cabelTvMovies, isPage) => ({
    type: isPage
        ? moviesActionTypes.FETCH_CABELTV_MOVIES_SUCCESS
        : moviesActionTypes.LOAD_MORE_CABELTV_MOVIES_SUCCESS,
	payload: cabelTvMovies,
});

export const fetchCabelTvMoviesFailure = error => ({
	type: moviesActionTypes.FETCH_CABELTV_MOVIES_FAILURE,
	payload: error,
});

export const fetchCabelTvMoviesAsync = (fetchUrl, isPage) => {
	let data = JSON.stringify({query: fetchUrl});
	let headers = {'x-api-key': 'da2-y7u3lgdrevdkrcxikpip5klpru'};
	return dispatch => {
		dispatch(fetchCabelTvMoviesRequest());
		axios
		.post('/', data, {headers: headers})
		.then((res) => {const cabelTvMovies = res.data.data.widgetbyPage.items.map(el => ({
				...el,
				isFavourite: false,
		}));
		if (isPage) {
			dispatch(fetchCabelTvMoviesSuccess(cabelTvMovies, isPage));
		} else dispatch(fetchCabelTvMoviesSuccess(cabelTvMovies));
	})
	.catch((error) => {
		const errorMessage = error.message;
		dispatch(fetchCabelTvMoviesFailure(errorMessage))
	});
	}
};



// Cabel TV Movies
export const fetchLatestMoviesRequest = () => ({
	type: moviesActionTypes.FETCH_LATEST_MOVIES_REQUEST,
});

export const fetchLatestMoviesSuccess = (latestMovies, isPage) => ({
    type: isPage
        ? moviesActionTypes.FETCH_LATEST_MOVIES_SUCCESS
        : moviesActionTypes.LOAD_MORE_LATEST_MOVIES_SUCCESS,
	payload: latestMovies,
});

export const fetchLatestMoviesFailure = error => ({
	type: moviesActionTypes.FETCH_LATEST_MOVIES_FAILURE,
	payload: error,
});

export const fetchLatestMoviesAsync = (fetchUrl, isPage) => {
	let data = JSON.stringify({query: fetchUrl});
	let headers = {'x-api-key': 'da2-y7u3lgdrevdkrcxikpip5klpru'};
	return dispatch => {
		dispatch(fetchLatestMoviesRequest());
		axios
		.post('/', data, {headers: headers})
		.then((res) => {const latestMovies = res.data.data.widgetbyPage.items.map(el => ({
				...el,
				isFavourite: false,
		}));
		if (isPage) {
			dispatch(fetchLatestMoviesSuccess(latestMovies, isPage));
		} else dispatch(fetchLatestMoviesSuccess(latestMovies));
	})
	.catch((error) => {
		const errorMessage = error.message;
		dispatch(fetchLatestMoviesFailure(errorMessage))
	});
	}
};