import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store"

const root = ReactDOM.createRoot(document.getElementById("application"));


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename="/">
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
)