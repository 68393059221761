import "./footer.scss"
import { motion } from "framer-motion";
import { FaFacebookSquare  } from 'react-icons/fa';
import { RiInstagramFill, RiTwitterFill  } from 'react-icons/ri';
import { TfiYoutube  } from 'react-icons/tfi';
import { creditsFadeInUpVariants } from "../../motionUtils";
import { WEBSITE_URL } from "../../requests";

const Footer = () => {
	return (		
		<motion.footer
			variants={creditsFadeInUpVariants}
			initial='initial'
			animate='animate'
			exit='exit'
			className='Footer'
		>
			<motion.div className='Footer__pagewrp' >
				<motion.div className='Footer__pagewrp__block'>
					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'/about-us'} >
						<span>About Us</span>
					</motion.a>
					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'/privacy-policy'} >
						<span>Privacy</span>
					</motion.a>
					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'/terms-service'} >
						<span>Terms of Service</span>
					</motion.a>
					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'#'} >
						<span>Help Center</span>
					</motion.a>
				</motion.div>

				<motion.div className='Footer__pagewrp__block'>
					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'https://www.facebook.com/@chmoviesott'} target='_blank' rel='noreferrer'>
						<FaFacebookSquare size={'2em'} />
					</motion.a>

					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'https://www.twitter.com/@chmoviesott'} target='_blank' rel='noreferrer'>
						<RiTwitterFill size={'2em'} />
					</motion.a>

					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'https://www.instagram.com/chmoviesott'} target='_blank' rel='noreferrer'>
						<RiInstagramFill size={'2em'} />
					</motion.a>

					<motion.a whileTap={{scale: 0.5}} className='Footer__pagewrp__block--links' href={'https://www.youtube.com/@chmoviesott'} target='_blank' rel='noreferrer'>
						<TfiYoutube size={'2em'} />
					</motion.a>
				</motion.div>
			</motion.div>

			<motion.div className='Footer__linkwrp' >
				<span>Copyright &copy;{(new Date().getFullYear())} </span>
				<motion.a whileTap={{scale: 0.5}} className='Footer__developer--link' href={WEBSITE_URL} target='_blank' rel='noreferrer'>
					<span>MERC MEDIA Private Limited. All Right Reserved</span>
				</motion.a>
			</motion.div>

			<motion.div className='Footer__developer Footer__developer--disabled' >
				<span>Developed by </span>
				<motion.a className='Footer__developer--link Footer__developer--link--disabled' href={"https://www.awtox.com"} target='_blank' rel='noreferrer'>
					<span>EDWARD ANTONY</span>
				</motion.a>
			</motion.div>
		</motion.footer>
	)
}

export default Footer;
