export const moviesActionTypes = {
    FETCH_HOME_MOVIES_REQUEST: 'FETCH_HOME_MOVIES_REQUEST',
    FETCH_HOME_MOVIES_SUCCESS: 'FETCH_HOME_MOVIES_SUCCESS',
    FETCH_HOME_MOVIES_FAILURE: 'FETCH_HOME_MOVIES_FAILURE',
    LOAD_MORE_HOME_MOVIES_SUCCESS: 'LOAD_MORE_HOME_MOVIES_SUCCESS',

    FETCH_CABELTV_MOVIES_REQUEST: 'FETCH_CABELTV_MOVIES_REQUEST',
    FETCH_CABELTV_MOVIES_SUCCESS: 'FETCH_CABELTV_MOVIES_SUCCESS',
    FETCH_CABELTV_MOVIES_FAILURE: 'FETCH_CABELTV_MOVIES_FAILURE',
    LOAD_MORE_CABELTV_MOVIES_SUCCESS: 'LOAD_MORE_CABELTV_MOVIES_SUCCESS',

    FETCH_LATEST_MOVIES_REQUEST: 'FETCH_LATEST_MOVIES_REQUEST',
    FETCH_LATEST_MOVIES_SUCCESS: 'FETCH_LATEST_MOVIES_SUCCESS',
    FETCH_LATEST_MOVIES_FAILURE: 'FETCH_LATEST_MOVIES_FAILURE',
    LOAD_MORE_LATEST_MOVIES_SUCCESS: 'LOAD_MORE_LATEST_MOVIES_SUCCESS',
}

