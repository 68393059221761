import "./poster.scss"
import { motion } from "framer-motion";
import { posterFadeInVariants } from "../../motionUtils";
import { BASE_IMG_URL, FALLBACK_IMG_LANDSCAPE, FALLBACK_IMG_PORTRAIT } from "../../requests";
import { FaChevronDown, FaMinus, FaPlay, FaPlus } from "react-icons/fa";
import { showModalDetail } from "../../redux/modal/modal.actions";
import { useDispatch } from "react-redux";
import { filterImg } from "../../utils";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";

const Poster = result => {
    const { item, item: { title, original_title, genres }, layout, isFavourite } = result;
    let fallbackTitle = title || original_title;
    const imageArrays = item?.VideoContents?.items[0]?.Images?.items;
	const filteredImage_row = filterImg(imageArrays, "P360", layout);
	const posterImage_row = BASE_IMG_URL + filteredImage_row?.image_path +"/"+ filteredImage_row?.quality.toLowerCase() + filteredImage_row?.file_name;
	const filteredImage = filterImg(imageArrays, "P360", "LANDSCAPE");
	const posterImage = BASE_IMG_URL + filteredImage?.image_path +"/"+ filteredImage?.quality.toLowerCase() + filteredImage?.file_name;
    const FALL_BACK_IMAGE = layout ==='LANDSCAPE'? FALLBACK_IMG_LANDSCAPE : FALLBACK_IMG_PORTRAIT;
    const dispatch = useDispatch();

    const handleAdd = event => {
        event.stopPropagation();
        dispatch(addToFavourites({ ...item, isFavourite }));
    };
    const handleRemove = event => {
        event.stopPropagation();
        dispatch(removeFromFavourites({ ...item, isFavourite }));
    };

    const handleModalOpening = () => {
        dispatch(showModalDetail({ ...item, fallbackTitle: title, posterImage, isFavourite }));
    }

    // const handlePlayAction = event => {
    //     event.stopPropagation();
    // };

    return (
        <motion.div
            variants={posterFadeInVariants}
            className='Poster'
            onClick={handleModalOpening}
        >
            {posterImage_row ? (
				<img src={posterImage_row} alt={title} />
			) : (
				<>
					<img src={FALL_BACK_IMAGE} alt={title} />
					<div className="Row__poster__fallback">
						<span>{title}</span>
					</div>
				</>
			)}
            <div className="Poster__info">
                <div className="Poster__info--iconswrp">
                    <button
                        className="Poster__info--icon icon--play"
                        onClick={handleModalOpening}
                    >
                        <FaPlay />
                    </button>
                    {!isFavourite
                        ? (
                            <button className='Poster__info--icon icon--favourite' onClick={handleAdd}>
                                <FaPlus />
                            </button>
                        ): (
                            <button className='Poster__info--icon icon--favourite' onClick={handleRemove}>
                                <FaMinus />
                            </button>
                        )}
                    <button className='Poster__info--icon icon--toggleModal'>
                        <FaChevronDown onClick={handleModalOpening}/>
                    </button>
                </div>
                <div className="Poster__info--title">
                    <h3>{fallbackTitle}</h3>
                </div>
                <div className="Poster__info--genres">
                    {genres?.items && genres?.items.map(item => (
						<span key={`Genre--id_${item.category.category_id}`} className="genre-title">{item.category.name}</span>
					))}
                </div>
            </div>
        </motion.div>
    )
}

export default Poster
