import { combineReducers } from "redux";
import homeMoviesReducer from "./homeMovies.reducer";
import cabelTvMoviesReducer from "./cabelTvMovies.reducer";
import latestMoviesReducer from "./latestMovies.reducer";

export default combineReducers({
    homeMovies: homeMoviesReducer,
    cabelTvMovies: cabelTvMoviesReducer,
    latestMovies: latestMoviesReducer,
})

