import { searchActionTypes } from "./search.types";
import axios from "../../axiosInstance";
//import requests from "../../requests";

const searchData = (searchQuery) => { 
	return `query MyQuery {
	searchVideoTitles(filter: {title: {matchPhrasePrefix: "${searchQuery}"}}) {
		items {
			title
			title_id
			original_title
			description
			release_date
			original_language

			genres {
				items {
				  category {
					category_id
					name
				  }
				}
			}
			VideoContents {
				items {
				  Images {
					items {
					  file_name
					  image_path
					  image_type
					  quality
					}
				  }
				}
			  }
		  }
		}
	}`
}


export const changeSearchInputValue = inputValue => ({
	type: searchActionTypes.CHANGE_SEARCH_INPUT_VALUE,
	payload: inputValue
})

export const clearSearchInputValue = () => ({
	type: searchActionTypes.CLEAR_SEARCH_INPUT_VALUE
})

export const fetchSearchResultsRequest = searchQuery => ({
	type: searchActionTypes.FETCH_SEARCH_RESULTS_REQUEST,
	payload: searchQuery
})

export const fetchSearchResultsSuccess = searchResults => ({
	type: searchActionTypes.FETCH_SEARCH_RESULTS_SUCCESS,
	payload: searchResults
})

export const fetchSearchResultsFailure = errorMessage => ({
	type: searchActionTypes.FETCH_SEARCH_RESULTS_FAILURE,
	payload: errorMessage
})

export const fetchSearchResultsAsync = searchQuery => {
	let data = JSON.stringify({query: searchData(searchQuery)});
	let headers = {'x-api-key': 'da2-y7u3lgdrevdkrcxikpip5klpru'};
	return dispatch => {
		dispatch(fetchSearchResultsRequest(searchQuery));
		axios.post('/', data, {headers: headers})
			.then(response => {
				const { data: { searchVideoTitles } } = response.data;
				const filteredResults = searchVideoTitles.items.filter(result => result.title_id !== '{{titleID}}');
				dispatch(fetchSearchResultsSuccess(filteredResults));
			})
			.catch(error => {
				dispatch(fetchSearchResultsFailure(error.message));
			});
	}
}