import { getActionTypes } from "./get.types";

const initialState = {
	getResults: '',
	inputValue: '',
	error: null,
	isLoading: true
}

const getReducer = (state = initialState, action) => {
	switch (action.type) {
		case getActionTypes.CHANGE_GET_INPUT_VALUE:
			return {
				...state,
				inputValue: action.payload
			}
		case getActionTypes.CLEAR_GET_INPUT_VALUE:
			return {
				...state,
				inputValue: ''
			}
		case getActionTypes.FETCH_GET_RESULTS_REQUEST:
			return {
				...state,
				getResults: {
					loading: true,
					error: false
				}
				
			}
		case getActionTypes.FETCH_GET_RESULTS_SUCCESS:
			return {
				...state,
				getResults: {
					error: false,					
					loading: false,
					data: action.payload
				}
			}
		case getActionTypes.FETCH_GET_RESULTS_FAILURE:
			return {
				...state,
				getResults: {
					data: [],
					error: action.payload,
					loading: false
				}
				
			}
		default:
			return state
	}
}

export default getReducer
