import "./popular.scss";
import Row from "../../components/Row/Row";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";

const Popular = () => {
	const polularData = useRetrieveData('movies');

	return (
		<motion.div
			className="Popular"
			variants={defaultPageFadeInVariants}
			initial="initial"
			animate="animate"
			exit="exit"
		>
			<Navbar />
			{polularData && polularData.map(props => (
                <div key={props.id}>
                    <Row key={props.id + 1} {...props} />
                </div>
            ))}
			<Footer />
		</motion.div>
	)
}

export default Popular