import './trailerModal.scss'
import { useRef } from 'react';
import { motion, AnimatePresence } from "framer-motion"
import { modalOverlayVariants, trailerVariants } from "../../motionUtils";
import { hideModalTrailer } from "../../redux/trailer/trailer.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectTrailerContent, selectTrailerState } from "../../redux/trailer/trailer.selectors";
import { VscChromeClose } from "react-icons/vsc";
//import useOnClickOutside from 'use-onclickoutside';
import Player from "react-player"

const TrailerModal = () => {

	const dispatch = useDispatch();
	const trailerClosed = useSelector(selectTrailerState);
	const trailerContent = useSelector(selectTrailerContent);
	const handleModalClose = () => dispatch(hideModalTrailer());
	const { trailerUrl } = trailerContent;
	

	const modalRef = useRef();
	
	// useOnClickOutside(modalRef, () => {
	// 	if (!trailerClosed) handleModalClose();
	// });
	
	return (
		<AnimatePresence>
			<>
			{!trailerClosed && (
				<>
					<motion.div
						variants={modalOverlayVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						key="trailerOverlay"
						className={`Trailer__overlay ${trailerClosed && 'Trailer__invisible'}`}
					>
						<motion.div
							key="modal"
							variants={trailerVariants}
							ref={modalRef}
							className={`Trailer__wrp ${trailerClosed && 'Trailer__invisible'}`}
						>
							<motion.button
								className="Trailer__closebtn"
								onClick={handleModalClose}
							>
								<VscChromeClose />
							</motion.button>
							<div className="Trailer__image--wrp">
								<Player								
									url={trailerUrl}
									controls={true}
									playing={true}						
									width="100%"
									height="100%"
									config={{ file: { attributes: { 
										disablePictureInPicture: 'true',
										controlsList: 'nodownload noplaybackrate',
									}}}}
								/>
							</div>
						</motion.div>
					</motion.div>
				</>
			)}
			</>
		</AnimatePresence>
	)
}

export default TrailerModal
