import './detailModal.scss'
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"
import { staggerOne, modalOverlayVariants, modalVariants, modalFadeInUpVariants } from "../../motionUtils";
import { hideModalDetail } from "../../redux/modal/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectModalContent, selectModalState } from "../../redux/modal/modal.selectors";
import { FALLBACK_IMG_LANDSCAPE } from "../../requests";
import { VscChromeClose } from "react-icons/vsc";
import { capitalizeFirstLetter, dateToYearOnly } from "../../utils";
import { FaMinus, FaPlay, FaPlus } from "react-icons/fa";
import { BiInfoCircle, BiRupee } from "react-icons/bi";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import useOnClickOutside from 'use-onclickoutside';

const DetailModal = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const modalClosed = useSelector(selectModalState);
	const modalContent = useSelector(selectModalContent);
	const handleModalClose = () => dispatch(hideModalDetail());
	const { fallbackTitle, title_id, description, original_title, posterImage, release_date, vote_average, original_language, genres, Credits, maturity_level, show_in, isFavourite } = modalContent;
	const type= show_in?.includes("CABELTV")

	const getGenres = genres?.items?.map((genre) => {
		return genre.category.name;
	});
	const joinedGenres = getGenres?.length > 0 && getGenres.join(", ");

	const getCast = Credits?.items[0]?.Cast?.items?.map((val) => (
		val.name
	));
	const joinedCast = getCast?.length > 0 && getCast.join(", ");

	const getCrew = Credits?.items[0]?.Crew?.items?.map((val) => (
		val.name
	));
	const joinedCrew = getCrew?.length > 0 && getCrew.join(", ");

	const maturityRating = maturity_level === undefined ? "U" : maturity_level === "SEVEN_PLUS" ? "U/A 7+" : maturity_level === "THIRTEEN_PLUS" ? "U/A 13+"  : maturity_level === "SIXTEEN_PLUS" ? "U/A 16+" : "A";
	const reducedDate = release_date ? dateToYearOnly(release_date) : release_date ? dateToYearOnly(release_date) : "Not Available";
	const modalRef = useRef();
	//const audioLanguages = video_languages.map((language) => language).join(", ");
	

	const handleAdd = (event) => {
		event.stopPropagation();
		dispatch(addToFavourites({ ...modalContent, isFavourite }));
	}
	const handleRemove = (event) => {
		event.stopPropagation();
		dispatch(removeFromFavourites({ ...modalContent, isFavourite }));
		if (modalClosed) handleModalClose();
	}
	const handlePlayVideo = (value) => {
		navigate('/play/'+value);
		if (!modalClosed) handleModalClose();
	};
	const handleRentInfo = (event) => {
		event.stopPropagation();
	}
	const handleMoreInfo = (type, value) => {
		if (!modalClosed) handleModalClose();
		if(type){
			navigate('/detail/'+value);
		}else{
			navigate('/play/'+value);
		}
		
	}
	useOnClickOutside(modalRef, () => {
		if (!modalClosed) handleModalClose();
	});

	return (
		<AnimatePresence>
			<>
			{!modalClosed && (
				<>
					<motion.div
						variants={modalOverlayVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						key="modalOverlay"
						className={`Modal__overlay ${modalClosed && 'Modal__invisible'}`}
					>
						<motion.div
							key="modal"
							variants={modalVariants}
							ref={modalRef}
							className={`Modal__wrp ${modalClosed && 'Modal__invisible'}`}
						>
							<motion.button
								className="Modal__closebtn"
								onClick={handleModalClose}
							>
								<VscChromeClose />
							</motion.button>
							<div className="Modal__image--wrp">
								<div className="Modal__image--shadow" />
								<img
									className="Modal__image--img"
									src={posterImage ? posterImage : FALLBACK_IMG_LANDSCAPE}
									alt={fallbackTitle}
								/>
								<div className="Modal__image--buttonswrp">
									<div>
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Modal__image--button"
											onClick={() => handlePlayVideo(title_id)}
											disabled
										>
											<FaPlay size="1em"/>
											{type ? (
												<span>Subscribe @ CabelTV</span>
											):(
												<span>Subscribe to Play</span>
											)}
											
										</motion.button>
									</div>
									<div className="Modal__image__buttons">
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Modal__image--button Modal__image__buttons--btn"
											onClick={(e) => handleRentInfo(e)}
											disabled
										>
											<BiRupee size="1.2em" />
											<span>Rent</span>
										</motion.button>
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Modal__image--button Modal__image__buttons--btn"
											onClick={() => handleMoreInfo(type, title_id)}
										>
											<BiInfoCircle size="1.2em" />
											<span>More Info</span>
										</motion.button>

										{!isFavourite
										? (
											<button className='Modal__image--button-circular' onClick={handleAdd}>
												<FaPlus />
											</button>
										): (
											<button className='Modal__image--button-circular' onClick={handleRemove}>
												<FaMinus />
											</button>
										)}
									</div>

									
								</div>
							</div>
							<motion.div variants={staggerOne} initial="initial" animate="animate" exit="exit" className="Modal__info--wrp">
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--title-wrp">
									<motion.h4 variants={modalFadeInUpVariants} className="Modal__info--title">{fallbackTitle}</motion.h4>
									{/* {original_title && (<motion.h6 className="Modal__info--original-title">( {fallbackTitle} )</motion.h6>)} */}
								</motion.div>
								{description && (
								<motion.p variants={modalFadeInUpVariants} className="Modal__info--description">{description}</motion.p>
								)}
								<motion.hr variants={modalFadeInUpVariants} className="Modal__info--line"/>
								{original_title && (
								<motion.h4 variants={modalFadeInUpVariants} className="Modal__info--otherTitle">Info on <b>{original_title}</b></motion.h4>
								)}
								{joinedGenres && (
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Genres: </span>
									<span className="Modal__info--row-description">{joinedGenres}</span>
								</motion.div>
								)}
								{joinedCast && (
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Cast: </span>
									<span className="Modal__info--row-description">{joinedCast}</span>
								</motion.div>
								)}
								{joinedCrew && (
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Crew: </span>
									<span className="Modal__info--row-description">{joinedCrew}</span>
								</motion.div>
								)}
								{reducedDate && (
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Release year: </span>
									<span className="Modal__info--row-description">{reducedDate}</span>
								</motion.div>
								)}
								{vote_average > 0 && (
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Average vote: </span>
									<span className="Modal__info--row-description">{vote_average || "Not available"}</span>
								</motion.div>
								)}
								{original_language && (
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Original language: </span>
									<span className="Modal__info--row-description">{capitalizeFirstLetter(original_language)}</span>
								</motion.div>
								)}
								{maturityRating && (
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Age classification: </span>
									<span className="Modal__info--row-description">{maturityRating}</span>
								</motion.div>
								)}
								{/* <motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Audio Available: </span>
									<span className="Modal__info--row-description">{audioLanguages}</span>
								</motion.div> */}
							</motion.div>
						</motion.div>
					</motion.div>
				</>
			)}
			</>
		</AnimatePresence>
	)
}

export default DetailModal
