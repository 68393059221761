const INITIAL_STATE  = {
  playing: false,
  controls: false,
  volume: 0.5,
  light: false,
  loop: false,
  muted: false,
  fullscreen: false,
  showctrl: false,
  subtitle: false,
  language: null,
  preview: true,
  progress: {
    playedSeconds: 0,
  },
  playbackRate: 1,
  duration: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
      case 'PLAY':
        return { ...state, playing: true };
      case 'PAUSE':
        return { ...state, playing: false };
      case 'TOGGLE_PLAY':
        return { ...state, playing: !state.playing };
      case 'DURATION':
        return { ...state, duration: action.payload };
      case 'SEEK':
        return { ...state, progress: { playedSeconds: action.payload } };
      case 'VOLUME':
        return { ...state, volume: action.payload };
      case 'MUTE_TOGGLE':
        return { ...state, muted: !state.muted };
      case 'LIGHT':
        return { ...state, light: action.payload };
      case 'CONTROLS':
        return { ...state, showctrl: action.payload };
      case 'PREVIEW':
        return { ...state, preview: action.payload };
      case 'PLAY_FROM':
        return { ...state, progress: {playedSeconds: action.payload} };
      case 'FULLSCREEN_TOGGLE':
        return { ...state, fullscreen: !state.fullscreen };
      case 'SUBTITLE':
        return { ...state, subtitle: action.payload };
      case 'LANGUAGE':
        return { ...state, language: action.payload };
      default:
        return state;
  }
};

export { reducer, INITIAL_STATE };
