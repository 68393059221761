//import { getOneMonthAgoReleaseDate } from "./utils";

export const WEBSITE_URL = "https://www.chmovies.com";
export const LINKEDIN_URL = "https://www.linkedin.com/company/awtox";
export const LANG = "en-US";
export const REGION = "IN";
export const BASE_IMG_URL = "https://cdn.chmovies.com";
export const BASE_URL = "https://www.chmovies.com";
export const TRAILER_URL = "https://cdn.chmovies.com";
export const POST_URL = "https://qp6q5kvkvze5dcmpuwzh4enddm.appsync-api.ap-south-1.amazonaws.com/graphql";
export const FALLBACK_IMG_LANDSCAPE = `./fall_back_image_landscape.png`;
export const FALLBACK_IMG_PORTRAIT = `./fall_back_image_portrait.png`;
export const CREDIT_BASE_URL = `https://d5c16ar39wjcw.cloudfront.net`;
export const LOGO_URL = `../logo.svg`;
export const MOBILE_LOGO_URL = `../logo.svg`;
export const PROFILE_PIC_URL = `../profilepic.png`;
export const CAST_BGIMG = `../cast_dark.jpg`;
export const CREW_BGIMG = `../crew_dark.jpg`;
export const SIGNIN_BGIMG_URL = `./auth_bg.jpg`;
export const TADUM_SOUND_URL = `./TaDum.mp3`;
//const ONEMONTHAGO = getOneMonthAgoReleaseDate();
//const { REACT_APP_API_KEY } = process.env;

const requests = {
//	fetchSearchQuery: "https://api.themoviedb.org/3/search/multi?api_key=079f5e4fae4c3f64e201b0f6f7479a38&query=",
//	fetchHomeMovies: `/discover/movie?api_key=${REACT_APP_API_KEY}&with_genres=10749&sort_by=popularity.desc&language=${LANG}`,
	fetchHomeMovies: `query MyQuery{widgetbyPage(page:HOME,sortDirection:ASC){items{id title type layout priority page widget_limit max_thumb_limit go_to_link VideoTitles{items{videoTitle{title title_id type tagline release_date maturity_level description runtime show_in vote_average original_language original_title video_languages VideoContents{items{Images{items{file_name image_path image_type quality}}}}genres{items{category{category_id name}}}Credits{items{Cast(sortDirection:ASC){items{name character}}Crew{items{name job}}}}VideoTrailers{items{file_name s3_path}}}}}}}}`,
	fetchCabelTvMovies: `query MyQuery{widgetbyPage(page:CABELTV,sortDirection:ASC){items{id title type layout priority page widget_limit max_thumb_limit go_to_link VideoTitles{items{videoTitle{title type title_id tagline release_date maturity_level description runtime show_in vote_average original_language original_title video_languages VideoContents{items{Images{items{file_name image_path image_type quality}}}}genres{items{category{category_id name}}}Credits{items{Cast(sortDirection:ASC){items{name character}}Crew{items{name job}}}}VideoTrailers{items{file_name s3_path}}}}}}}}`,
	fetchLatestMovies: `query MyQuery{widgetbyPage(page:MOVIE,sortDirection:ASC){items{id title type layout priority page widget_limit max_thumb_limit go_to_link VideoTitles{items{videoTitle{title title_id type tagline release_date maturity_level description runtime show_in vote_average original_language original_title video_languages VideoContents{items{Images{items{file_name image_path image_type quality}}}}genres{items{category{category_id name}}}Credits{items{Cast(sortDirection:ASC){items{name character}}Crew{items{name job}}}}VideoTrailers{items{file_name s3_path}}}}}}}}`,

};

export default requests;
