import { getActionTypes } from "./get.types";
import axios from "../../axiosInstance";
//import requests from "../../requests";

const getDatabyId = (id) => { 
	return `query MyQuery {
	getVideoTitle(title_id: "${id}") {
		title
		title_id
		description
		type
		release_date
		publish_date
		maturity_level
		original_title
		vote_count
		vote_average
		video_languages
		tags
		tagline
		original_language
		monetization_model
		runtime
		genres {
		  items {
			category {
			  category_id
			  name
			}
		  }
		}
		VideoMetaData {
		  items {
			title
			description
		  }
		}
		Rents {
		  items {
			package_name
			pricing {
			  UHD
			  SD
			  HD
			}
			currency
		  }
		}
		Credits {
		  items {
			Cast(sortDirection: ASC) {
			  items {
				name
				character
				file_name
			  }
			}
			Crew {
			  items {
				name
				job
				known_for_department
				file_name
			  }
			}
		  }
		}
		SubTitles {
		  items {
			name
			language
		  }
		}
		VideoContents {
		  items {
			Images {
			  items {
				quality
				image_path
				file_name
				image_type
			  }
			}
			Hls {
			  items {
				quality
				hls_path
				file_name
			  }
			}
		  }
		}
		VideoTrailers {
		  items {
			file_name
			s3_path
		  }
		}
	  }
	}`
  }

export const changeGetInputValue = inputValue => ({
	type: getActionTypes.CHANGE_GET_INPUT_VALUE,
	payload: inputValue
})

export const clearGetInputValue = () => ({
	type: getActionTypes.CLEAR_GET_INPUT_VALUE
})

export const fetchGetResultsRequest = getQuery => ({
	type: getActionTypes.FETCH_GET_RESULTS_REQUEST,
	payload: getQuery
})

export const fetchGetResultsSuccess = getResults => ({
	type: getActionTypes.FETCH_GET_RESULTS_SUCCESS,
	payload: getResults
})

export const fetchGetResultsFailure = errorMessage => ({
	type: getActionTypes.FETCH_GET_RESULTS_FAILURE,
	payload: errorMessage
})

export const fetchGetResultsAsync = getQuery => {
	let data = JSON.stringify({query: getDatabyId(getQuery)});
	let headers = {'x-api-key': 'da2-y7u3lgdrevdkrcxikpip5klpru'};
	return dispatch => {
		dispatch(fetchGetResultsRequest(getQuery));
		axios.post('/', data, {headers: headers})
			.then(response => {
				const { data: { getVideoTitle } } = response.data;
				dispatch(fetchGetResultsSuccess(getVideoTitle));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchGetResultsFailure(errorMessage));
			});
	}
}