import { Box, Fade, styled, Typography } from '@mui/material';



const StyledPlayerOverlay = styled('div')`
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: end;
  left: 0;
  top: 0;
  opacity: ${({ state }) => (state.playing ? '0' : '0.7')};
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 62%);

      .Player__overlay-inner {
          display: block;
          width: 100%;
          height: 100%;
          background: transparent;
      }

      .Player__overlay-wrapper {
          display: block;
          width: 60%;
          height: 100%;
          margin-top: 25vh;
          margin-left: 15vh;
      }


`;


const PlayerOverlay  = (props) => {
  const { state, data } = props;

  const getRunTime = () => {
    const hours = Math.floor(state.duration / 3600);
    const minutes = Math.floor((state.duration % 3600) / 60);
    return `${hours}h  ${minutes}m`;
  };
  const maturityRating =
  data?.maturity_level == undefined
      ? "U"
      : data?.maturity_level === "SEVEN_PLUS"
      ? "U/A 7+"
      : data?.maturity_level === "THIRTEEN_PLUS"
      ? "U/A 13+"
      : data?.maturity_level === "SIXTEEN+PLUS"
      ? "U/A 16+"
      : "A";

  const typeHD = "HD";
  
  return (
    <StyledPlayerOverlay state={state} className={'Player__overlay-inner'}>
      <Box className={'Player__overlay-wrapper'}>
        <Fade in color={'white'}>
          <span>You are watching</span>
        </Fade>
        <Fade in>
          <Typography variant="h4" sx={{ mt:1, fontSize:'2.5rem', fontWeight:'600', color:'#d39e00' }}>
             {data?.title}
          </Typography>
        </Fade>
        <Fade in>
          <Typography sx={{ mt:1, fontSize: '1rem', color:'white', width:'60%'}}>
            {data?.description}
          </Typography>
        </Fade>
        <Fade in>
          <Typography variant="span" sx={{ mt:2, fontSize:'1.2rem', color:'white' }}>
            <span>{data?.release_date.slice(0, 4)} <span className='maturity_box'>{maturityRating}</span> {getRunTime()} <span className='hd_box'>{typeHD}</span></span>
          </Typography>
        </Fade>
      </Box>
    </StyledPlayerOverlay>
  );
};

export default PlayerOverlay;
