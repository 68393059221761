import "./movies.scss"
//import Banner from "../../components/Banner/Banner"
import Row from "../../components/Row/Row";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";

const Movies = () => {
    const moviesData = useRetrieveData('latest');

    return (
        <motion.div
            className="Movies"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Navbar />
            {moviesData && moviesData.map(props => (
                <div key={props.id}>
                    {/* <Banner key={props.id} {...props} /> */}
                    <Row key={props.id + 1} {...props} style={{marginTop: "20px"}} />
                </div>
            ))}
            <Footer />
        </motion.div>
    )
}

export default Movies
