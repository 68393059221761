/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://qp6q5kvkvze5dcmpuwzh4enddm.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-y7u3lgdrevdkrcxikpip5klpru",
    "aws_cognito_identity_pool_id": "ap-south-1:d1c4f3b3-39ad-4017-910a-ec307992a25e",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_Jsg3n4CWr",
    "aws_user_pools_web_client_id": "stug2eil77r454d0v0pfo3uf6",
    "oauth": {
        "domain": "chpasswordless.auth.ap-south-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_user_files_s3_bucket": "chmoviesdata-storage-bucket-video184742-staging ",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
