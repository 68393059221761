import "./credits.scss";
import SkeletonElement from "../SkeletonElement/SkeletonElement";
import SkeletonPoster from "../SkeletonPoster/SkeletonPoster";
import useViewport from "../../hooks/useViewport";
import CreditPoster from "../CreditPoster/CreditPoster"

// Swiper
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
SwiperCore.use([Navigation, Pagination]);

const Credits = data => {
	const { width } = useViewport();
	const { loading, data: items, title } = data;

	const customSwiperParams = {
        observer: false,
        observeParents: false,
		navigation: true,
		pagination: false,
		loop: false,
		grabCursor: false,
		draggable: false,
		preventClicksPropagation: true,
		preventClicks: true,
		slideToClickedSlide: false,
		allowTouchMove: true,
		breakpoints:{
			1378: { slidesPerView: 8, slidesPerGroup: 8 },
			998: { slidesPerView: 6, slidesPerGroup: 6 },
			625: { slidesPerView: 5, slidesPerGroup: 5 },
			330: { slidesPerView: 4, slidesPerGroup: 4 },
			0: { slidesPerView: 3, slidesPerGroup: 3 }
		}
    };

	

	const rightMouseOver = (e) => {
		if (e.currentTarget.classList.contains('right')) {e.currentTarget.parentElement.classList.add('is-right')}
		else if (e.currentTarget.classList.contains('left')) {e.currentTarget.parentElement.classList.add('is-left')}
	}

	const rightMouseOut = (e) => {
		e.currentTarget.parentElement.classList.remove('is-right', 'is-left')
	}

	const insertPositionClassName = (index) => {
		const i = index + 1

		if (i === 1) return 'left'
		else if (i === 20) return 'right'

		if (width >= 1378) {
			if ([7, 13, 19].includes(i)) return 'left'
			else if ([6, 12, 18].includes(i)) return 'right'
		} else if (width >= 998) {
			if ([5, 9, 13, 17].includes(i)) return 'left'
			else if ([4, 8, 12, 16].includes(i)) return 'right'
		} else if (width >= 768) {
			if ([4, 7, 10, 13, 16].includes(i)) return 'left'
			else if ([3, 6, 9, 12, 15, 18].includes(i)) return 'right'
		}
	}
	
	return (
		<div className={`Credits`} >
			
			{loading || !items ?
				(
					<div className='Credits__not-loaded'>
						<SkeletonElement type="title" />
						<SkeletonPoster />
					</div>
				) : (			
				<>
					<h3 className="Credits__title">
						<span>{title}</span>
					</h3>
					<div className="Credits__poster--wrp">						
						<Swiper
							{...customSwiperParams}	
						>
							{!loading && items &&
								items.map((item, i) => (
									<SwiperSlide
										key={i}
										className={insertPositionClassName(i)}
										onMouseOver={rightMouseOver}
										onMouseOut={rightMouseOut}
									>
										<CreditPoster
											item={item}
											layout={"PORTRAIT"}
											isLarge={false}
											key={i + 5}
										/>
									</SwiperSlide>
								))
							}
						</Swiper>
					</div>
				</>
			)}
		</div>
	);
};

export default Credits;