export const authActionTypes = {
    CHECK_USER_SESSION: "CHECK_USER_SESSION",
    CHECK_USER_UNREACHABLE: "CHECK_USER_UNREACHABLE",

    SIGN_IN_START: "SIGN_IN_START",
    GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",
    ANONYMOUS_SIGN_IN_START: 'ANONYMOUS_SIGN_IN_START',
    SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
    SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
    
    VERIFY_OTP_START: "VERIFY_OTP_START",
    SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
    SEND_OTP_FAILURE: "SEND_OTP_FAILURE",

    SIGN_UP_START: 'SIGN_UP_START',
    SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
    SIGN_UP_FAILURE: "SIGN_UP_FAILURE",

    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
    SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",

    SIGNUP_NEW_START: "SIGNUP_NEW_START"
}
