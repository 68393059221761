import "./myList.scss"
import Poster from "../../components/Poster/Poster";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { motion } from "framer-motion";
import { staggerHalf, defaultPageFadeInVariants } from "../../motionUtils";
import { useSelector } from "react-redux"
import { selectFavouritesList } from "../../redux/favourites/favourites.selectors"

const MyList = () => {
    const favs = useSelector(selectFavouritesList);
console.log(favs)
    return (
        <motion.div
            className="MyList"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Navbar />
            
            <motion.div
                className="MyList__wrp"
                variants={staggerHalf}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                {favs && favs.length > 0 && (
                    <h2 className="MyList__title">My List</h2>
                )}
                {favs && favs.length > 0
                    ? favs.map((result, i) => (
                        <Poster
                            key={i}
							item={result}
							layout={"LANDSCAPE"}
							{...result}
                        />
                    ))
                    : (
                        <h2 className="MyList__title">
                            Sorry, you don&apos;t have a watched movie or favoutate list yet.
                        </h2>
                    )
                }
            </motion.div>
            <Footer />
        </motion.div>
    )
}

export default MyList