import "./trailer.scss";
import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Grid, Box } from '@mui/material';
import { Facebook, Twitter, YouTube, Instagram  } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import { clearGetInputValue, fetchGetResultsAsync } from "../../redux/get/get.actions";
import { selectGetResults } from "../../redux/get/get.selectors";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants } from "../../motionUtils";
import { formatDate, maturityLevel, formatRunTime } from "./../../utils";
import { BASE_IMG_URL, FALLBACK_IMG_LANDSCAPE, TRAILER_URL } from "../../requests";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Credits from "./../../components/Credits/Credits";
import { FaPlay } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import { MdSlowMotionVideo } from "react-icons/md";
//import { useNavigate } from "react-router-dom";
import SkeletonBanner from "../../components/SkeletonBanner/SkeletonBanner";
import LinesEllipsis from 'react-lines-ellipsis'
import useViewport from "../../hooks/useViewport";
//import { useDispatch, useSelector } from "react-redux";
import { filterImg } from "../../utils";
//import { showModalDetail } from "../../redux/modal/modal.actions";
import { showModalTrailer } from "../../redux/trailer/trailer.actions";
//import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";



const Trailer = () => {
	const { id } = useParams(); 
	const layout = "LANDSCAPE";
	const dispatch = useDispatch();
	const layoutWidth = useViewport();
	
	useEffect(() => {
		if(id && id.length > 0){
			dispatch(fetchGetResultsAsync(id));		
		}else{
			dispatch(clearGetInputValue());
		}
	},[id, dispatch]);

	const lineNumber = layoutWidth.width < 400? 2 : layoutWidth.width < 450 ? 2 : layoutWidth.width < 550 ? 3 : 4;
	const getResults = useSelector(selectGetResults); 
	const { loading, error, data } = getResults;
    const imageArrays = data?.VideoContents?.items[0]?.Images?.items;
	const filteredImage = filterImg(imageArrays, "P720", layout);
	const posterImage = BASE_IMG_URL + filteredImage?.image_path +"/"+ filteredImage?.quality.toLowerCase() + filteredImage?.file_name;
	const bannerImage = posterImage? posterImage : FALLBACK_IMG_LANDSCAPE;

	const cast = data?.Credits?.items[0]?.Cast?.items?.map((val) => (
		{name:val.name, character:val.character, profileImg: val.file_name}
	));

	const crew = data?.Credits?.items[0]?.Crew?.items?.map((val) => (
		{name:val.name, job:val.job, department:val.known_for_department, profileImg: val.file_name}
	));

	let trailerUrl = "";
	data?.VideoTrailers?.items.map((item) => {
		if(item){
			trailerUrl = TRAILER_URL+item?.s3_path+item?.file_name;
		}
	});
	
	
	const customSwiperParams = {
        slidesPerView: 1,
		spaceBetween: 30,
		centeredSlides: true,
		loop: true,
		effect: "fade",
		autoplay: false,
		pagination: false,
		navigation: false,
	//	modules: [Autoplay, Pagination, Navigation]
    };

	

	
    return (
		<>
			<Navbar />
			{loading ? (
				<motion.section
					variants={bannerFadeInLoadSectionVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="Trailer__loadsection"
				>
					{loading && <SkeletonBanner />}
					{error && <div className="errored">Oops, an error occurred.</div>}
				</motion.section>
			) : (
			<>
				<motion.header
					key={12}
					variants={bannerFadeInVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="Banner"
				>
					
						<Swiper
							key={1}
							{...customSwiperParams}
							className="Banner__slider"
						>

							<SwiperSlide 
								key={11}
								virtualIndex={'index'} 
								style={{backgroundImage: `url(${bannerImage})` }} 
								className="Banner__slider__wrapper"
							>
								<motion.div
									className="Banner__slider__content"
									variants={staggerOne}
									initial='initial'
									animate='animate'
									exit='exit'
								>
									<motion.h1 variants={bannerFadeInUpVariants} className="Banner__slider__content--title">{data?.title}</motion.h1>
									<motion.p variants={bannerFadeInUpVariants} className="Banner__slider__content--description">
										<LinesEllipsis text={data?.description} maxLine={lineNumber} ellipsis='...' trimRight basedOn='letters' />
									</motion.p>
									<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Banner__button Banner__button--large"
										//	onClick={() => handlePlayVideo(items.videoTitle.title_id)}
											title="Subscribe"
											disabled
										>
											<FaPlay />
											<span>Subscribe for CabelTV to Watch Premire</span>
										</motion.button>
									</motion.div>
									<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons Banner__buttons--secondary">
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Banner__button Banner__button--trailer"
											onClick={() => dispatch(showModalTrailer({trailerUrl}))}
											title="Subscribe"
										>
											<MdSlowMotionVideo size="1.5em" />
											<span>Trailer</span>
										</motion.button>
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Banner__button"
											disabled
										//	onClick={() => dispatch(showModalDetail({...items.videoTitle, fallbackTitle:items.videoTitle.title, posterImage: posterImage[i], isFavourate: false }))}
										//	onClick={() => handlePlayVideo(items.videoTitle.title_id)}
										>
											<BiRupee size="1.5em" />
											<span>Rent</span>
										</motion.button>
									</motion.div>
									
								</motion.div>
								<div className="Trailer__bg" />
							</SwiperSlide>							
							<div className="Trailer__bottom-shadow" />
					</Swiper>
						
				  </motion.header>

				  <motion.div>
				  <Grid container spacing={1}>
						<Grid item xs={12} sm={9}>
						<Credits data={cast} title="Cast Members" />
						<Credits data={crew} title="Crew Members" />
						</Grid>
						<Grid item xs={12} sm={3}>
						<Box className="Player__poster-info">
							<Box className="Player__info--social">
							<Box variant="span" className='Player__info--social-label' sx={{ marginBottom: '5px' }}>Follow Us at: </Box>
							<Box>
								<Link to={"https://www.facebook.com/chmoviesott"} target="_blank">
								<Facebook  sx={{ color: 'white', fontSize: '2rem' }} />
								</Link>
								<Link to={"https://www.twitter.com/chmoviesott"} target="_blank">
								<Twitter  sx={{ color: 'white', fontSize: '2rem', marginRight: '5px' }} />
								</Link>
								<Link to={"https://www.instagram.com/chmoviesott"} target="_blank">
								<Instagram  sx={{ color: 'white', fontSize: '2rem', marginRight: '5px' }} />
								</Link>
								<Link to={"https://www.youtube.com/@chmoviesott"} target="_blank">
								<YouTube  sx={{ color: 'white', fontSize: '2rem',  marginRight: '5px' }} />
								</Link>
							</Box>
										</Box>
						</Box>
						<Box className="Player__poster-info">
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Release Date: </span>
										{data?.publish_date === null ? (
											<span className="Player__info--extras-description">Coming Soon</span>	
										):(
											<span className="Player__info--extras-description">{formatDate(data?.release_date)}</span>
										)}
										</Box>
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Original Title: </span>
											<span className="Player__info--extras-description">{data?.original_title}</span>
										</Box>
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Original Language: </span>
											<span className="Player__info--extras-description">{data?.original_language}</span>
										</Box>
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Rating: </span>
											<span className="Player__info--extras-description">{data?.vote_average}</span>
										</Box>
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Subtitles: </span>
											<span className="Player__info--extras-description">English, മലയാളം, தமிழ், हिंदी</span>
										</Box>
							{data?.audio_languages && (
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Audio: </span>
											<span className="Player__info--extras-description">{data?.audio_languages}</span>
										</Box>
							)}
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Maturity Rating: </span>
											<span className="Player__info--extras-description">{maturityLevel(data?.maturity_level)}</span>
										</Box>
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Run TIme: </span>
											<span className="Player__info--extras-description">{formatRunTime(data?.runtime)}</span>
										</Box>
							<Box className="Player__info--extras">
											<span className='Player__info--extras-label'>Available in : </span>
											<span className="Player__info--extras-description">SD | HD | UHD</span>
										</Box>
						</Box>
						</Grid>
					</Grid>
				  </motion.div>
			
			 </>
			)}
			<Footer />
		  </>
    )
}

export default Trailer
