import './signUp.scss';
import 'react-phone-input-2/lib/style.css';
import './phoneInput.scss';
import React, { useState, useEffect, Fragment } from "react"
//import InputField from "../InputField/InputField";
import Loader from "../Loader/Loader";
import { motion } from "framer-motion";
import { authFadeInUpVariants, staggerOne } from "../../motionUtils";
import { useForm, Controller  } from "react-hook-form";
import { SignInStart, verifyCodeStart, signUpStart } from "../../redux/auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthLoadingState, selectAuthChallengeStatus, selectSignupRequest } from "../../redux/auth/auth.selectors";
import OTPInput, { ResendOTP } from "otp-input-react";
import PhoneInput from "react-phone-input-2";


const SignUp = () => {
	const dispatch = useDispatch();
	const [code, setCode] = useState("");
	//const [userEmail, setUserEmail] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [formMode, setFormMode] = useState(true);
	const isLoading = useSelector(selectAuthLoadingState);
	const user = useSelector(selectAuthChallengeStatus);
	const signup = useSelector(selectSignupRequest);
//	const [buttonDisabled, setButtonDisabled] = useState("disabled");
	const { control, register, handleSubmit, formState: { errors } } = useForm({mode: "all"});

	useEffect(() => {
		if(signup?.request === "phone"){ 
			setFormMode(false); 
		}
		
	},[formMode, signup])
	
	const onSubmit = (data) => {
		if(formMode){
			const { email } = data;
			setUserEmail(email);
			dispatch(SignInStart({ username: email, medium:"email" }));
		}
		else{			
			const { phone } = data;
			if(!signup){
				dispatch(SignInStart({ username:"+"+phone, medium:"sms" }));
			}else{
				dispatch(signUpStart({ email:userEmail, phone_number: "+"+phone }));
			}
		}
	}
	const onVerify = () => {
		dispatch(verifyCodeStart({ user, code }));
	}
	

	

	// const getRunTime = (time) => {
	// 	const minutes = Math.floor(time / 60);
	// 	const seconds = Math.floor((time % 60) / 60);
	// 	return `${minutes}m  ${seconds}s`;
	// };
	const renderTime = () => Fragment;
	const renderButton = (buttonProps) => {
		let totalSec = buttonProps?.remainingTime;
		let minutes = "0" + Math.floor(totalSec / 60);
		let counter = Math.floor(totalSec % 60);
		let seconds = counter > 9 ? counter : "0"+counter;
		return (
		<button className="SignIn__form--input--resendBtn" {...buttonProps}>
			{buttonProps?.remainingTime !== 0 ? `Please wait for ${minutes +":"+ seconds}` : "Resend"}
		</button>
		);
	};
	
	

	const isValidNumber = (value, country) => {
		let number  = value?.slice(country?.dialCode?.length);
		if (number?.match(/12345/)) {
			return 'Invalid value: '+value+ " "+country.name;
		} 
		else if (number?.match(/1234/)) {
			return 'Please enter a valid number';
		}else{
			if(number?.length > 5){
				//setButtonDisabled("");
			}
			return true;
		}
	};

	
	//const validation = createRef();

	return (
		<>
		<motion.h2 variants={authFadeInUpVariants} className="Auth__content--title">
			{ signup ? "SignUp" : user ? "Enter OTP" : !signup && !user && !formMode ? "Sign In Mobile" : "Sign In Email" }

		</motion.h2>
		<motion.form
			variants={staggerOne}
			initial="initial"
			animate="animate"
			exit="exit"
			className="SignIn__form"
			onSubmit={handleSubmit(!user? onSubmit : onVerify)}
			
		>
			{!user? (
				<>
				{formMode ? (
					<>
					<motion.p>{signup && (<p>Please enter your email address.</p>)}</motion.p>
					<motion.div variants={authFadeInUpVariants} className="SignIn__form--inputwrp">
						
						<input
							type="text"
							name="email"
							placeholder="yourname@gmail.com"
							className={`InputField ${errors?.email && "Input--error"}`}
							{...register("email", {
									required: "Please enter your email address",
									pattern: {
										value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: "Please enter a valid email address.",
									},
								})}
							disabled={isLoading}
						/>
						{errors?.email && (<p className="InputField__label">{errors?.email.message}</p>)}
					</motion.div>
					</>
				) : (
					<motion.div variants={authFadeInUpVariants} className="SignIn__form--inputwrp">
						<Controller
								control={control}
								name="phone"
								render={({ field: { onChange } }) => (
								<PhoneInput
									country = {'in'}
									preferredCountries={['in', 'gb', 'us']}
									onChange={onChange}
									enableSearch={false}
									disableSearchIcon={true}
									countryCodeEditable={false}
									isValid={isValidNumber}
								/>
								)}
							/>
							{errors?.phone && (<p className="InputField__label">{errors?.phone.message}</p>)}
						{/* <OTPInput name="inputOtp" className={'SignIn__form--input--otp'} value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} />
						<ResendOTP className={'SignIn__form--input--resend'} renderButton={renderButton} renderTime={renderTime} maxTime={180} /> */}
					</motion.div>
				
				)}
			  </>
			) : (
				<motion.div variants={authFadeInUpVariants} className="SignIn__form--inputwrp">
					<OTPInput 
						type="text"
						name="code"
						className={'SignIn__form--input--otp'} 
						value={code} 
						onChange={setCode} 
						autoFocus 
						OTPLength={6} 
						otpType="number" 
					/>
					<ResendOTP className={'SignIn__form--input--resend'} renderButton={renderButton} renderTime={renderTime} maxTime={180} />
				</motion.div>
			)}

			<motion.button
				type="submit"
				variants={authFadeInUpVariants}
				className={`SignIn__form--button button__submit ${isLoading && 'loading'}`}
				//disabled={buttonDisabled? buttonDisabled : isLoading}
				disabled={isLoading}
			>
				{isLoading ? <Loader /> : 'Sign in'}
			</motion.button>

		</motion.form>
	</>
	)
}

export default SignUp;