import "./banner.scss";
import React from "react";
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants } from "../../motionUtils";
import { BASE_IMG_URL, TRAILER_URL } from "../../requests";
import { FaPlay } from "react-icons/fa";
import { BiInfoCircle, BiRupee } from "react-icons/bi";
import { MdSlowMotionVideo } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import SkeletonBanner from "../SkeletonBanner/SkeletonBanner";
import LinesEllipsis from 'react-lines-ellipsis'
import { useDispatch, useSelector } from "react-redux";
import { filterImg } from "../../utils";
//import { showModalDetail } from "../../redux/modal/modal.actions";
import { showModalTrailer } from "../../redux/trailer/trailer.actions";
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useViewport from "./../../hooks/useViewport";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const Banner = ({ selector }) => {

	const rowData = useSelector(selector);
	const navigate = useNavigate();
	const layout = useViewport();
	const lineNumber = layout.width < 400? 2 : layout.width < 450 ? 2 : layout.width < 550 ? 3 : 4;
	const { loading, error, data: widget } = rowData;
	let posterImage = [];
	let dispatchImage = [];
	let trailerUrl = [];
	let img = ""
	widget && widget.map((data) => (
		data?.type === 'SLIDER' && (
			
			data?.VideoTitles &&
			data.VideoTitles?.items.map((items) => (
				img= filterImg(items.videoTitle?.VideoContents?.items[0].Images?.items, "P720", "LANDSCAPE"),	
				dispatchImage.push(img),
				posterImage.push(`${BASE_IMG_URL}${img.image_path}/${img.quality.toLowerCase()}${img.file_name}`),
				items.videoTitle?.VideoTrailers?.items?.length > 0 && (
				trailerUrl.push({trailerUrl:TRAILER_URL+items.videoTitle?.VideoTrailers.items[0]?.s3_path+items.videoTitle?.VideoTrailers.items[0]?.file_name})
				)
			))
			
		)
	));

	const dispatch = useDispatch();
	const handlePlayVideo = (type, value) => {
		if(type){
			navigate('/detail/'+value);
		}else{
			navigate('/play/'+value);
		}
		
	};

	const customSwiperParams = {
        slidesPerView: 1,
		spaceBetween: 30,
		centeredSlides: true,
		loop: true,
		effect: "fade",
		autoplay: {
			delay: 5000,
			disableOnInteraction: false
		},
		pagination: {
			clickable: false
		},
		navigation: true,
		modules: [Autoplay, Pagination, Navigation]
    };

	

	
	

	return (
			<>
			{loading ? (
				<motion.section
					variants={bannerFadeInLoadSectionVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="Banner__loadsection"
				>
					{loading && <SkeletonBanner />}
					{error && <div className="errored">Oops, an error occurred.</div>}
				</motion.section>
			) : (
			<>
				{widget && widget.map((data, i) => (
				data?.type === 'SLIDER' && (
					
				<motion.header
					key={i}
					variants={bannerFadeInVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="Banner"
				>
					
						<Swiper
							key={1}
							{...customSwiperParams}
							className="Banner__slider"
						>
						{!loading &&
						data?.VideoTitles &&
						data.VideoTitles?.items.map((items, i) => (
							<SwiperSlide 
								key={i}
								virtualIndex={'index'} 
								style={{backgroundImage: `url(${posterImage[i]})` }} 
								className="Banner__slider__wrapper"
							>
								<motion.div
									className="Banner__slider__content"
									variants={staggerOne}
									initial='initial'
									animate='animate'
									exit='exit'
								>
									<motion.h1 variants={bannerFadeInUpVariants} className="Banner__slider__content--title">{items.videoTitle.title}</motion.h1>
									<motion.div variants={bannerFadeInUpVariants} className="Banner__slider__content--description">
										<LinesEllipsis text={items.videoTitle.description} maxLine={lineNumber} ellipsis='...' trimRight basedOn='words' />
									</motion.div>
									<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Banner__button Banner__button--large"
											onClick={() => handlePlayVideo(items.videoTitle.title_id)}
											title="Subscribe"
											disabled
										>
											<FaPlay size="1em"/>	
											{items.videoTitle.show_in.includes("CABELTV") ? (
												<span>Subscribe for CabelTV to Watch Premire</span>
											) : (
												<span>Subscribe to Play</span>
											)}
											
										</motion.button>
									</motion.div> 
									<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons Banner__buttons--secondary">
										{trailerUrl?.length > 0 && (
											<motion.button
												whileHover={{ scale: 1.1 }}
												className="Banner__button Banner__buttons--secondary--btn"
												onClick={() => dispatch(showModalTrailer(trailerUrl[i]))}
												title="Subscribe"
											>
												<MdSlowMotionVideo size="1.5em" />	
												<span>Trailer</span>
											</motion.button>
										)}
										
										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Banner__button Banner__buttons--secondary--btn"
										//	onClick={() => dispatch(showModalDetail({...items.videoTitle, fallbackTitle:items.videoTitle.title, posterImage: posterImage[i], isFavourate: false }))}
											onClick={() => handlePlayVideo(widget[i].page, items.videoTitle.title_id)}
											disabled
										>
											<BiRupee size="1.5em" />
											<span>Rent</span>
										</motion.button>

										<motion.button
											whileHover={{ scale: 1.1 }}
											className="Banner__button Banner__buttons--secondary--btn"
										//	onClick={() => dispatch(showModalDetail({...items.videoTitle, fallbackTitle:items.videoTitle.title, posterImage: posterImage[i], isFavourate: false }))}
											onClick={() => handlePlayVideo(items.videoTitle.show_in.includes("CABELTV"), items.videoTitle.title_id)}
										>
											<BiInfoCircle size="1.5em" />
											<span>More info</span>
										</motion.button>
									</motion.div>
									
								</motion.div>
								<div className="Banner__panel" />
							</SwiperSlide>
						))}
						<div className="Banner__bottom-shadow" />
					</Swiper>
						
				  </motion.header>
				)))}
			 </>
			)}
		  </>
	)
}

export default React.memo(Banner);