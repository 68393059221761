import { createSelector } from "reselect";

export const selectHomeMovies = state => state.movies.homeMovies;
export const selectCabelTvMovies = state => state.movies.cabelTvMovies;
export const selectLatestMovies = state => state.movies.latestMovies;

export const selectHomeMoviesSelector = createSelector(
    [selectHomeMovies],
    homeMovies => homeMovies.data
)

export const selectCabelTvMoviesSelector = createSelector(
    [selectCabelTvMovies],
    cabelTvMovies => cabelTvMovies.data
)

export const selectLatestMoviesSelector = createSelector(
    [selectCabelTvMovies],
    latestMovies => latestMovies.data
)
