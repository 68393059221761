import { authActionTypes } from "./auth.types"

const initialState = {
    currentUser: null,
    error: null,
    loading: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.SIGN_IN_START:
        case authActionTypes.GOOGLE_SIGN_IN_START:
        case authActionTypes.ANONYMOUS_SIGN_IN_START:
        case authActionTypes.SIGN_UP_START:
            return {
                ...state,
                loading: true
            }
        case authActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                loading: false,
                error: null
            }
        case authActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                currentUser: null,
                challengeStatus: null,
                loading: false,
                error: null
            }
        case authActionTypes.SEND_OTP_SUCCESS:
            return {
                ...state,
                challengeStatus: action.payload,
                loading: false,
                error: null
            }
        case authActionTypes.VERIFY_OTP_START:
                return {
                    ...state,
                    verifyStatus: action.payload,
                    loading: true,
                    error: null
        }
        
        case authActionTypes.SIGNUP_NEW_START:
            return {
                ...state,
                loading: false,
                request: action.payload
            }
        case authActionTypes.SEND_OTP_FAILURE:
        case authActionTypes.SIGN_IN_FAILURE:
        case authActionTypes.SIGN_UP_FAILURE:
        case authActionTypes.SIGN_OUT_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

export default authReducer
