import { createSelector } from "reselect";

const trailerModal = state => state.trailerModal;

export const selectTrailerState = createSelector(
	[trailerModal],
	trailerModal => trailerModal.trailerIsClosed
)

export const selectTrailerContent = createSelector(
	[trailerModal],
	trailerModal => trailerModal.trailerContent
)