import "./playvideo.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectGetResults } from "../../redux/get/get.selectors";
import { changeGetInputValue, clearGetInputValue, fetchGetResultsAsync } from "../../redux/get/get.actions";
import { motion } from "framer-motion";
import { bannerFadeInLoadSectionVariants } from "../../motionUtils";
import SkeletonBanner from "./../../components/SkeletonBanner/SkeletonBanner";
import { BASE_IMG_URL, FALLBACK_IMG_LANDSCAPE } from "../../requests";
import { useParams } from "react-router-dom";
import { filterImg, filterVideo } from "../../utils";
import Player from "./../../components/Player/Player";


const PlayVideo = () => {
	const { id } = useParams(); 
	const layout = "LANDSCAPE";
	const dispatch = useDispatch();
	
	useEffect(() => {
		if(id && id.length > 0){
			dispatch(changeGetInputValue(id));
			dispatch(fetchGetResultsAsync(id));		
		}else{
			dispatch(clearGetInputValue());
		}
	},[id, dispatch]);

	const getResults = useSelector(selectGetResults);

	
	const { loading, error, data } = getResults;
    const imageArrays = data?.VideoContents?.items[0]?.Images?.items;
	//const filteredImage_row = filterImg(imageArrays, "P720", layout);
	//const posterImage_row = BASE_IMG_URL + filteredImage_row?.image_path +"/"+ filteredImage_row?.quality.toLowerCase() + filteredImage_row?.file_name;
	const filteredImage = filterImg(imageArrays, "P720", layout);
	const posterImage = BASE_IMG_URL + filteredImage?.image_path +"/"+ filteredImage?.quality.toLowerCase() + filteredImage?.file_name;
	const bannerImage = posterImage? posterImage : FALLBACK_IMG_LANDSCAPE;
	const videoArrays = data?.VideoContents?.items[0]?.Hls?.items;
	const filteredVideo = filterVideo(videoArrays, "P720");
	const video_quality = filteredVideo?.quality === "P720"? "720p" : "1080p";
	const videoUrl = BASE_IMG_URL + filteredVideo?.hls_path +"/"+ video_quality + filteredVideo?.file_name;

	const getCast = data?.Credits?.items[0]?.Cast?.items?.map((val) => (
		{name:val.name, character:val.character, profileImg: val.file_name}
	));

	const getCrew = data?.Credits?.items[0]?.Crew?.items?.map((val) => (
		{name:val.name, job:val.job, department:val.known_for_department, profileImg: val.file_name}
	));

	// useEffect(() => {
	// 	setBanner(bannerImage);
	// 	setHlsUrl(videoUrl);
	// },[bannerImage, videoUrl]);

	



	
    return (
		<>
		{loading ? (
			<motion.section
					variants={bannerFadeInLoadSectionVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="PlayVideo__loadsection"
				>
					{loading && <SkeletonBanner />}
					{error && <div className="errored">Oops, an error occurred.</div>}
			</motion.section>
		) : (
			<>
			<Player url={videoUrl} poster={bannerImage} data={data} cast={getCast} crew={getCrew} />
			</>
		)}
	</>
    )
}

export default PlayVideo
