import { authActionTypes } from "./auth.types"

export const checkUserSession = () => ({
    type: authActionTypes.CHECK_USER_SESSION
})

export const SignInStart = ({ username, medium }) => ({
    type: authActionTypes.SIGN_IN_START,
    payload: { username, medium }
})

export const verifyCodeStart = ({ user, code })  => ({
    type: authActionTypes.VERIFY_OTP_START,
    payload: { user, code }
})

export const otpVerifiedSuccess = user => ({
    type: authActionTypes.OTP_VERIFIED_SUCCESS,
    payload: user
})

export const otpVerifiedFailure = error => ({
    type: authActionTypes.OTP_VERIFIED_FAILURE,
    payload: error
})

export const signInSuccess = user => ({
    type: authActionTypes.SIGN_IN_SUCCESS,
    payload: user
})

export const signInFailure = error => ({
    type: authActionTypes.SIGN_IN_FAILURE,
    payload: error
})

export const checkUserFailure = error => ({
    type: authActionTypes.CHECK_USER_UNREACHABLE,
    payload: error
})

export const sendOtpSuccess = otp => ({
    type: authActionTypes.SEND_OTP_SUCCESS,
    payload: otp
})

export const sendOtpFailure = error => ({
    type: authActionTypes.SEND_OTP_FAILURE,
    payload: error
})

export const signOutStart = () => ({
    type: authActionTypes.SIGN_OUT_START
})

export const signOutSuccess = () => ({
    type: authActionTypes.SIGN_OUT_SUCCESS
})

export const signOutFailure = error => ({
    type: authActionTypes.SIGN_OUT_FAILURE,
    payload: error
})

export const signUpStart = userCredentials => ({
    type: authActionTypes.SIGN_UP_START,
    payload: userCredentials
})

export const signUpSuccess = ({ user, additionalData }) => ({
    type: authActionTypes.SIGN_UP_SUCCESS,
    payload: { user, additionalData }
})

export const signUpFailure = error => ({
    type: authActionTypes.SIGN_UP_FAILURE,
    payload: error
})

export const signupStarting = request => ({
    type: authActionTypes.SIGNUP_NEW_START,
    payload: request
})