import { IconButton, Slider, Stack, styled, Typography } from '@mui/material';
//import { PlayArrowOutlined, PauseOutlined, FullscreenRounded, VolumeUpOutlined, VolumeOffOutlined, Forward10, Replay10, SpeakerNotesOutlined, SpeedOutlined  } from '@mui/icons-material';
import { GrBackTen, GrForwardTen, GrVolume, GrVolumeMute, GrPlayFill, GrPauseFill, GrClosedCaption  } from 'react-icons/gr';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { SiSpeedtest } from "react-icons/si";
import screenfull from 'screenfull';
//import { findDOMNode } from 'react-dom';

const StyledPlayerControls = styled('div')`
    position: absolute;
    padding: 10px 15px;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    border: 0px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .time-stack .MuiStack-root{
        font-size: 0.9rem;
        margin-right: -5px;
        justify-content: end;
    }


      .Player__slider {
        width: 100%;
        left: 5px;
        color: #fff;
        box-sizing: border-box;
        margin-right: 2em;
        height: 5px;

        &--seek {
          margin-left: 0px;
          margin-right: 0px;
        }

        &--sound {
          width: 100px;
               
        }
        &--volume {
          color: #ffffff;
        }

        .MuiSlider-track {
          border: none;
          color: #d39e00;
          -webkit-transition: background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transition: background-color 5ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .MuiStack-root {
          display:none;
          align-items: end;
          margin-left: 4px;
          margin-right: 5px;
          padding-left: 3px;
          padding-right: 2px;
          -webkit-transition: background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transition: background-color 5ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        .MuiSlider-rail {
          background-color: #ffffff;
          &:hover {
            background-color: #ffffff;
          }
        }

        .MuiSlider-thumb {
          background-color: #d39e00;
          width: 12px;
          height: 12px;

          &:before: {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }

          &:hover,
          &.Mui-focusVisible,
          &.Mui-active {
            box-shadow: none;
            background-color: #ffcf00;
          }
        }
      }

      .Player__volume {
        margin-left: 2px;

        &--seek {
          margin-left: 12px;
          margin-right: 12px;
        }

        &--sound {
          height: 60px;
          position: absolute;
          bottom: 55px;
          opacity: 0;
          
          &:hover {
            opacity: 1;
          } 

          .MuiSlider-rail {
                left: 6px;
            }
        }

        &--volume {
          color: #ffffff;
        }

        .MuiSlider-track {
          border: none;
          color: #ffffff;
        }

        .MuiSlider-thumb {
          background-color: #ffffff;
          width:12px;
          height:12px;

          &:before: {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }

          &:hover,
          &.Mui-focusVisible,
          &.Mui-active {
            box-shadow: none;
          }
        }

      }

      .Player__button {
        padding: 3px 4px;

        &:hover{
          opacity: 1;
          transform:scale(1.3);
          transition: 0.5s ease-in;
        }

        path{
          stroke:white;
        }
        &--vol {
          &:hover {
            opacity: 1;
            transform:scale(1.3);
            transition: 0.5s ease-in;
          }
        }

      }
      .Player__slider {
        &:hover {
          transform: scale(1) !important;
        }
      }

`;

const PlayerControls = (props) => {
  const { state, dispatch, wrapperRef, playerRef } = props;
 

  const handleSound = (e) => {
    dispatch({ type: 'VOLUME', payload: e });
  };

  const handleFullscreen = () => {
    screenfull.toggle(wrapperRef.current);
    dispatch({ type: 'FULLSCREEN_TOGGLE' })
  };

  const handleVolume = () => {
    dispatch({ type: 'MUTE_TOGGLE' });
  };

  const handleToggle = () => {
    dispatch({ type: 'TOGGLE_PLAY' });
  };

  const handleClosedCaption = () => {
    dispatch({ type: 'SUBTITLE', payload: true });
    dispatch({ type: 'LANGUAGE', payload: 'en' });
  };

  const handleClosedCaptionDiasble = () => {
    //  dispatch({ type: 'SUBTITLE', payload: false });
      dispatch({ type: 'LANGUAGE', payload: 'ta' });
  };
//console.log(state.language);
//console.log(playerRef.current);


  // const handlePlaybackSpeed = () => {
  //   console.log("Clicked")
  // };

  const handleSeek = (newValue) => {
    playerRef.current.seekTo(newValue, 'seconds');
  };

  const handleForward = () => {
   playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10, 'seconds');
  };

  const handleReplay = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10, 'seconds');
  };




 
  const renderSeekSlider = () => {
    return (
      <Slider
        aria-label="Time"
        className={'Player__button Player__slider Player__slider--seek'}
        min={0}
        max={state.duration}
        step={0.01}
        value={state.progress.playedSeconds}
        onChange={(e) => handleSeek(parseFloat(e.target.value))}
      />
    );
  };

  const renderPlayButton = () => {
    return (
      <IconButton className='Player__button' sx={{ fontSize: '2.5vw', opacity:'0.7' }} onClick={handleToggle}>
        {state.playing ? (
          <GrPauseFill />
        ):(
          <GrPlayFill />
        )}
      </IconButton>
    );
  };

  const renderSoundSlider = () => {
    return (
      <Stack spacing={2} direction="row"  alignItems="center">  
        <IconButton className='Player__button--vol' sx={{ fontSize: '2.5vw', opacity:'0.7' }} onClick={handleVolume}>
        {!state.muted && state.volume ? (
          <GrVolume />
        ):(
          <GrVolumeMute />
        )}
        <Slider
          aria-label="Volume"
          className={'Player__button Player__volume Player__volume--sound'}
          max={1}
          step={0.01}
          value={state.muted ? 0 : state.volume}
          onChange={(e) => handleSound(parseFloat(e.target.value))}
          orientation="vertical"
          sx={{ color:'white', opacity:'0.7', '& input[type="range"]': {WebkitAppearance: 'slider-vertical'} }}
        />
        </IconButton>
        
        <IconButton className='Player__button' sx={{ fontSize: '2.5vw', opacity:'0.7' }} onClick={handleReplay}>
          <GrBackTen />
        </IconButton>
        <IconButton className='Player__button' sx={{ fontSize: '2.5vw', opacity:'0.7' }} onClick={handleForward}>
          <GrForwardTen />
        </IconButton>
      </Stack>
    );
  };

  const format = (seconds) => {
    if (isNaN(seconds)) {
      return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };


  const renderDurationText = () => {
    return (
      <Stack spacing={1} direction="row" sx={{ ml: 0.5, px: 1, fontSize: '2.5vw' }} alignItems="right" >
        <Typography variant="span" color="white" sx={{ display: 'flex' }} className='Player__button'>
          {format(state.duration - state.progress.playedSeconds)}
        </Typography>
      </Stack>
    );
  };

  // const marks = [
  //   {
  //     value: 4,
  //     label: '4x',
  //   },
  //   {
  //     value: 3,
  //     label: '3x',
  //   },
  //   {
  //     value: 2,
  //     label: '2x',
  //   },
  //   {
  //     value: 1,
  //     label: '1x',
  //   },
  // ];
  
  // const valuetext = (value) => {
  //   return `${value}`;
  // }

  const renderFullscreenButton = () => {
    return (
      <>
        <IconButton className='Player__button' sx={{ color: 'white', fontSize: '2.5vw', opacity:'0.7' }} onClick={handleClosedCaption} >
          <GrClosedCaption />
        </IconButton>
        <IconButton className='Player__button' sx={{ color: 'white', fontSize: '2.5vw', opacity:'0.7' }} onClick={handleClosedCaptionDiasble}>
          <SiSpeedtest />
          {/* <Box className='Player__button--speed-wrapper'>
            <Slider
                  className='Player__button--speed-selector'
                  aria-label="Restricted values"
                  defaultValue={1}
                  valueLabelFormat={valuetext}
                  step={1}
                  valueLabelDisplay="auto"
                  marks={marks}
              />
          </Box> */}
        </IconButton>
        <IconButton className='Player__button' sx={{ color: 'white', fontSize: '2.5vw', opacity:'0.7' }} onClick={handleFullscreen}>
          {!state.fullscreen ?  (
           <RiFullscreenFill />
          ):(
            <RiFullscreenExitFill />
          )}
        </IconButton>
      </>
    );
  };


  return (
    <StyledPlayerControls className="Player__controls">
      <Stack direction="row" alignItems="center">
        {renderSeekSlider()} <span className="time-stack">{renderDurationText()}</span>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          {renderPlayButton()} {renderSoundSlider()} 
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {renderFullscreenButton()}
        </Stack>
      </Stack>
    </StyledPlayerControls>
  );
};

export default PlayerControls;
