import { takeLatest, all, put, call } from "redux-saga/effects";
import { authActionTypes } from "./auth.types";
import { signupStarting, sendOtpSuccess, sendOtpFailure, signInSuccess, signInFailure, signOutFailure, signOutSuccess, signUpFailure, signUpSuccess, checkUserFailure } from "./auth.actions";
import { Auth, Amplify } from "aws-amplify";
import configure from "./../auth/aws-exports";
import { setInLocalStorage, clearInLocalStorage } from "./../../utils"
Amplify.configure(configure);

export function* getSnapshotFromUserAuth(userAuth) {
	try {
		const userData = { 
			id: userAuth.idToken?.payload?.sub, 
			username: userAuth?.accessToken?.payload?.username, 
			email: userAuth?.idToken?.payload?.email, 
			phone: userAuth?.idToken?.payload?.phone_number,
			access: userAuth?.idToken?.payload['cognito:groups'][0]
		}
		yield put(signInSuccess(userData));
		setInLocalStorage("userData", userData);
	} catch (e) {
		yield put(checkUserFailure(e.message));
	}
}

export function* sendOtpChallenge({ userAuth, medium}) {
	try {
		yield Auth.sendCustomChallengeAnswer(userAuth, "dumy", {
			medium: medium? medium : "sms",
			source: "sendCustomChallengeAnswer",
		});
		yield put(sendOtpSuccess(userAuth));
	} catch (e) {
		yield put(sendOtpFailure(e.message));
	}
}

export function* signIn({payload: { username, medium }}) {
	try {
		const userAuth = yield Auth.signIn({ username });
		if(userAuth.challengeName === "CUSTOM_CHALLENGE"){
			yield sendOtpChallenge({ userAuth, medium });
		}	
	} catch (e) {
		let error = JSON.parse(JSON.stringify(e));
		if(error.code === "UserLambdaValidationException"){
			const request = medium === "sms" ? "email" : "phone";
			yield put(signupStarting({ request }));
		} else {
			yield put(signInFailure("SignIn Failed! Please try again later."));
		}
	}
}


export function* signUp({payload: { email, phone_number }}){
	try {
		const emailid= email.replace(/\s+/g,'').toLowerCase();
		const uname = emailid.replace(/@.*$/,"");
		const { user } = yield Auth.signUp({
			username:uname, 
			password: emailid+"RandomOrange1$1&0#0@", 
			attributes: {
				email:emailid, 
				phone_number
			}});
		
	const signup = yield put(signUpSuccess({ user }))
	if(signup){
		yield signIn({payload:{ username:phone_number, medium:"sms" }} );
	}
	} catch (e) {
		yield put(signUpFailure(e.message));
	}
}

export function* verifyOtp({payload: { user, code }}) {
	try {
		yield Auth.sendCustomChallengeAnswer( user, code );
		const session = yield Auth.currentSession();
		yield getSnapshotFromUserAuth({ session })
	} catch (e) {
		yield put(signInFailure(e.message));
	}
}

export function* signOut(){
	try {
		yield Auth.signOut();
		yield put(signOutSuccess());
		clearInLocalStorage("userData");
	} catch (e) {
		yield put(signOutFailure(e.message));
	}
}


export function* checkIfUserIsAuthenticated(){
	try {
		const session = yield Auth.currentSession();
		if (!session) return;
		const userData = { 
			id: session.idToken?.payload?.sub, 
			username: session.accessToken?.payload?.username, 
			email: session.idToken?.payload?.email, 
			phone: session.idToken?.payload?.phone_number,
			access: session.idToken?.payload['cognito:groups'][0]
		} 
		yield put(signInSuccess(userData));
		setInLocalStorage("userData", userData);
	} catch (e) {
		yield put(checkUserFailure("Unable to Reach the User"));
	}
}



// export function* signInAfterSignUp({payload: { user }}){
// 	console.log(user);
// 	//yield getSnapshotFromUserAuth(user);
// }

export function* onCheckUserSession(){
	yield takeLatest(authActionTypes.CHECK_USER_SESSION, checkIfUserIsAuthenticated);
}

// export function* onGoogleSignInStart(){
// 	yield takeLatest(authActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
// }

export function* onSignInStart(){
	yield takeLatest(authActionTypes.SIGN_IN_START, signIn);
}

export function* onverifyCodeStart(){
	yield takeLatest(authActionTypes.VERIFY_OTP_START, verifyOtp);
}

// export function* onAnonymousSignInStart(){
// 	yield takeLatest(authActionTypes.ANONYMOUS_SIGN_IN_START, signInAnonymously);
// }

export function* onSignOutStart(){
	yield takeLatest(authActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart(){
	yield takeLatest(authActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess(){
	//yield takeLatest(authActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* authSagas() {
	yield all([
		call(onCheckUserSession),
	//	call(onGoogleSignInStart),
		call(onSignInStart),
		call(onverifyCodeStart),
		call(onSignOutStart),
		call(onSignUpStart),
		call(onSignUpSuccess),
	]);
}