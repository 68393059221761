import "./page.scss";
//import Banner from "../../components/Banner/Banner";
//import Row from "../../components/Row/Row";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
//import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { staggerHalf, defaultPageFadeInVariants } from "../../motionUtils";

const Aboutus = () => {
    //const movieData = useRetrieveData('movies');
    return (
        <motion.div
            className="Homepage"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Navbar />
            <motion.div
                className="Page__wrp"
                variants={staggerHalf}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                
                
                <div>
                <h1 className="Page__title">About Us</h1>

                <p>Last updated: December 01, 2023</p>

                <p>This About Us describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>

                <h2>Interpretation and Definitions</h2>

                <h3>Interpretation</h3>

                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                <h3>Definitions</h3>

                <p>For the purposes of this Privacy Policy:</p>

                </div>
            </motion.div>
            <Footer />
        </motion.div>
    )
}

export default Aboutus
